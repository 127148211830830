import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/Pages/company_pages/navbar-account.css";

const SITE_LOGO = process.env.REACT_APP_SITE_LOGO;


function Navbar() {

  useEffect(() => {
    const hamburger = document.querySelector(".navbar-account__toggle");
    hamburger.addEventListener("click", () => {
      const sidebar = document.querySelector(".sidebar");
      
      if (window.innerWidth > 1180) {
        sidebar.classList.toggle("minimize");
        const sidebar_minimize = (localStorage.getItem("sidebar_minimize") === "true");
        localStorage.setItem("sidebar_minimize", !sidebar_minimize);
      }else {
        const blackScreen = document.querySelector(".black-screen");
        sidebar.classList.toggle("active");
        blackScreen.classList.add("active");
      }
    });
  }, []);

  return (
    <div className="navbar-account">
      <button className="navbar-account__toggle">
        <div className="bar-line"></div>
        <div className="bar-line"></div>
        <div className="bar-line"></div>
      </button>

      <h2 className="navbar-account__text">Dashboard</h2>

      <span className="navbar__logo">
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
          <img src={SITE_LOGO + "?tr=h-40"} alt="Logo" /> 
        </Link>
      </span>
    </div>
  );
}

export default Navbar;

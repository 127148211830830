import React, { useEffect, useRef } from "react";
import { api } from "../../../backend";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";


const visibleOptions = [
  {color: "green", name: "Active", value: 0},
  {color: "blue", name: "Paused", value: 2},
]

const defaultOptions = [
  {color: "green", name: "Active"},
  {color: "gray", name: "completed"},
  {color: "blue", name: "Paused"},
  {color: "red", name: "closed"},
]

function DropDown({ upward_menu=false, title="status", active, campaign_id="" }) {
  const session_id = localStorage.getItem("session_id");
  const status = useRef(0);
  const navigate = useNavigate();
  const visible = active === 0 || active === 2;

  const changeState = async (value) => {
    if (value === 2) {
      const confirm = window.confirm("You campaign will not be visible. Are you sure?");
      if (!confirm) return false;
    }

    try {
      await api.put(`/company/campaign/status?session_id=${session_id}&campaign_id=${campaign_id}&status=${value}`);
      return true;
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    if (!visible) {
      return;
    }
    const dropdownOpts = status.current.querySelectorAll(".dropdown-menu__option");
    dropdownOpts.forEach((opt) => {
      opt.addEventListener("click", async () => {
        const statusText = status.current.querySelector(".status-text"); 
        if (statusText.innerHTML === opt.innerHTML){
          return
        }

        const changeSuccess = await changeState(parseInt(opt.dataset.value));
        if (!changeSuccess) {
          return;
        };
        statusText.innerHTML = opt.innerHTML;
        status.current.querySelector(".format-div").className = "format-div " + opt.dataset.color;
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={status} className="campaign__filters__status normal-status ">
      <h4>{title}</h4>
      <div className={"format-div " + defaultOptions[active || 0].color}>
        <p className="status-text">{defaultOptions[active || 0].name}</p>
        { (visible) && <i className="uil uil-angle-down"></i>}
      </div>

      {visible && (
        <div className={"dropdown-menu" + (upward_menu?" upward-menu":"")} >
          {
            visibleOptions.map((opt) => (
              <p
                className={"dropdown-menu__option " + opt?.name.toLowerCase()}
                data-color={opt?.color}
                key={uuidv4()}
                data-value={opt?.value}
              >{opt?.name}</p>
            ))
          }
        </div>
      )}
    </div>
  );
}

export default DropDown;

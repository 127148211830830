import React, { useEffect, useState } from "react";
import Navbar from "../../default_pages/Navbar";
import Footer from "../../default_pages/Footer";
import "../../../css/Pages/job_seeker/profile/main.css";
import UserInfo from "./UserInfo";
import UserDetails from "./UserDetails";
import Popup from "./Popup";
import { useNavigate } from "react-router-dom";
import { api } from "../../../backend";

function Main() {
  const navigate = useNavigate();
  const session_id = localStorage.getItem("session_id");
  const session_type = localStorage.getItem("session_type");

  if (session_id && session_type !== "job_seeker") {
    navigate("/my-account");
  }

  if (!session_id) {
    navigate("/login");
  }

  const [data, setData] = useState({});
  const [popup, setPopup] = useState({ state: false, type: "info", edu_type: "", action: "" });

  const hidePopup = async (refresh=true) => {
    document.body.classList.remove("fixed");
    setPopup({ state: false, type: ""});
    if (refresh) {
      await popuplateInfoApi();
    }
  }

  const popuplateInfoApi = async () => {
    try {
      const register_api = await api.get(`/register/job-seeker/search-id?session_id=${session_id}`);
      const profile_api = await api.get(`/profile/job-seeker?session_id=${session_id}`);

      setData({
        profile: register_api.data?.user.profile,
        full_name: register_api.data?.user.full_name,
        mobile: register_api.data?.user.mobile,
        email: register_api.data?.user.email,
        ...(profile_api.data?.user_profile)})

    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }

  useEffect(() => {
    if (session_id && session_type !== "job_seeker") {
      navigate("/my-account");
    }

    window.scrollTo(0, 0);
    popuplateInfoApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {popup?.state && (
        <Popup
          input_type={popup?.type}
          hidePopup={hidePopup}
          action={popup?.action}
          edu_type={popup?.edu_type}
          id={popup?.id}
          data={popup?.data}
          setResumeLink={popup?.setResumeLink}
        />
      )}
      <Navbar />
      <div className="user-profile">
        <UserInfo setPopup={setPopup} data={data} />
        <UserDetails setPopup={setPopup} data={data} />
      </div>
      <Footer />
    </>
  );
}

export default Main;

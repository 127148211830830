import React, { useEffect, useState } from "react";
import "../../../css/Pages/job_seeker/profile/popup.css";
import { api } from "../../../backend";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const locations = JSON.parse(process.env.REACT_APP_LOCATION);

function getLocation(query) {
  const filtered = [];

  if (!query) {
    return filtered;
  }

  for (let location of locations) {
    if (location.toLowerCase().indexOf(query.toLowerCase()) === 0) {
      filtered.push(location);

      if (filtered.length === 5) {
        break;
      }
    }
  }

  return filtered;
}

function Popup({ hidePopup, data="", populateData }) {
  const navigate = useNavigate();
  const session_id = localStorage.getItem("session_id");

  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    const popup = document.querySelector(".profile-popup");
    popup.addEventListener("click", (e) => {
      if (e.target.classList.contains("profile-popup")){
        hidePopup(false);
      }
    });
    document.body.classList.add("fixed");

    const state = document.querySelector("#state");
    state.addEventListener("input", () => {
      const choices = getLocation(state.value);
      if (choices.length > 0) {
        setStateOptions(getLocation(state.value));
        state.nextSibling.classList.add("visible");
      }
      else {
        state.nextSibling.classList.remove("visible");
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveFunction = async () => {
    const admin_name = document.getElementById("admin")?.value;
    const comp_mobile = document.getElementById("mobile")?.value;
    const city = document.getElementById("city")?.value;
    const state = document.getElementById("state")?.value;
    const website = document.getElementById("website")?.value;
    const address = document.getElementById("address")?.value;

    try {
      await api.put(`/register/company?session_id=${session_id}`, {
        admin_name, comp_mobile, city, state
      });
      await api.put(`/profile/company?session_id=${session_id}`, {
        website, address
      });
      hidePopup();
      populateData();
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }


  return (
    <div className="profile-popup">
      <div className="main-popup">
          <h4 className="popup-heading">Basic details</h4>

          <div className="profile__div name">
            <h4 className="profile__div--title">Admin Name</h4>
            <input
              type="text"
              placeholder="Enter Admin Name"
              className="input-outline"
              id="admin"
              defaultValue={data?.admin_name || ""}
            />
          </div>

          <div className="profile__div mobile">
            <h4 className="profile__div--title">Mobile number</h4>
            <div className="pretext-container">
              <p className="pretext">+91</p>
              <input type="number" id="mobile" placeholder="Mobile Number" defaultValue={data?.comp_mobile || ""} />
            </div>
          </div>

          <div className="profile__div city">
            <h4 className="profile__div--title">City</h4>
            <input
              type="text"
              placeholder="Enter City Name"
              className="input-outline"
              id="city"
              defaultValue={data?.city || ""}
            />
          </div>

          <div className="profile__div state">
            <h4 className="profile__div--title">State</h4>
            <input
              type="text"
              placeholder="Enter State Name"
              className="input-outline"
              id="state"
              defaultValue={data?.state || ""}
            />
            <ul className="filters__choice__box__selection">
              {stateOptions.map((choice) => (
                <li
                  key={uuidv4()}
                  onClick={(e) => {
                    document.querySelector("#state").value = choice;
                    e.currentTarget.parentElement.classList.remove("visible");
                  }}
                  className="result">{choice}</li>
              ))}
            </ul>
          </div>

          
          <div className="profile__div city">
            <h4 className="profile__div--title">Website</h4>
            <input
              type="text"
              placeholder="Enter Your Website Link"
              className="input-outline"
              id="website"
              defaultValue={data?.website || ""}
            />
          </div>

          <div className="profile__div address">
            <h4 className="profile__div--title">Company's Address</h4>
            <textarea
              type="text"
              placeholder="Enter your company's address"
              className="input-outline"
              id="address"
              defaultValue={data?.address || ""}
            />
          </div>

          <div className="profile__btns">
            <button className="profile__btns--save" onClick={(e) => saveFunction(e)}>Save</button>
            <button className="profile__btns--cancel" onClick={() => hidePopup(false)}>Cancel</button>
          </div>
      </div>
    </div>
  );
}

export default Popup;

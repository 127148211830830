import React, { useEffect, useState } from 'react'
// import "../../../css/Pages/company_pages/helpers/main.css";
import Section from './Section'
import Popup from './Popup'
import { api } from '../../../backend';
import { useNavigate } from 'react-router-dom';


function Main() {
  const navigate = useNavigate();
  const session_id = localStorage.getItem('session_id');
  const [popupInfo, setPopupInfo] = useState({ visible: false });
  const [helpers, setHelpers] = useState([]);

  const populateHelpers = async () => {
    try {
      const api_call = await api.get(`/register/helper/all?session_id=${session_id}`);
      setHelpers(api_call.data.helpers);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  const createHelper = async (values) => {
    try {
      await api.post(`/register/helper?session_id=${session_id}`, values);
      setPopupInfo({ visible: false });
      populateHelpers();
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        const error = document.querySelector(".error");
        error.classList.add("active");
        error.innerText = err.response.data.msg;
      }
    }
  }

  const updateHelper = async (values) => {
    try {
      await api.put(`/register/helper?session_id=${session_id}&helper_id=${popupInfo.helper_id}`, values);
      setPopupInfo({ visible: false });
      populateHelpers();
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        const error = document.querySelector(".error");
        error.classList.add("active");
        error.innerText = err.response.data.msg;
      }
    }
  }

  const removeHelper = async (helper_id) => {
    const confirm = window.confirm('Are you sure you want to remove this helper?');
    if (!confirm) {
      return
    }

    try {
      await api.delete(`/register/helper?session_id=${session_id}&helper_id=${helper_id}`);
      setPopupInfo({ visible: false });
      populateHelpers();
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        const error = document.querySelector(".error");
        error.classList.add("active");
        error.innerText = err.response.data.msg;
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    populateHelpers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="helper">
      <Section
        title="Helpers"
        updatePopup={(values) => {setPopupInfo({ ...values, update: true, visible: true })}}
        helpers={helpers}
        createPopup={() => {setPopupInfo({ visible: true })}}
        allowed={2}
        removeFunc={removeHelper}
      />

      { popupInfo?.visible && (
        <Popup
          info={popupInfo}
          update={popupInfo?.update}
          createFunc={createHelper}
          updateFunc={updateHelper}
          hidePopup={() => setPopupInfo({ visible: false })}
        />
      )}
    </div>
  )
}

export default Main
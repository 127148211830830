import React from "react";
import "../../../css/Pages/company_pages/helpers/card.css";

function Card({ name, email, updatePopup, removeFunc }) {
  return (
    <div className="manager-card">
      <div className="wrapper">
        <div className="manager-card__info">
          <span className="manager-card__info--text">
            <h4>Name: </h4>
            <p>{name}</p>
          </span>

          <span className="manager-card__info--text">
            <h4>Email: </h4>
            <p>{email}</p>
          </span>
        </div>
      </div>

      <div className="card__btns">
        <button className="card__btns--update" onClick={() => updatePopup({name, email, update: true})}>Update</button>
        <button className="card__btns--remove" onClick={removeFunc}>Remove</button>
      </div>
    </div>
  );
}

export default Card;

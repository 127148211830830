import React, { useEffect, useRef, useState } from "react";
import "../../../css/Pages/company_pages/stages/section.css";
import Stages from "./Stages";
import { api } from "../../../backend";
import Popup from "./Popup";
import { useNavigate } from "react-router-dom";


function Section({ editFnc, data, populateSections }) {
  const navigate = useNavigate();
  const ref = useRef(false);
  const session_id = localStorage.getItem("session_id");
  const [stages, setStages] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const activateBtn = (e) => {
    e.currentTarget.classList.toggle("active");
    ref.current.querySelector(".stage_section__stages").classList.toggle("active");
  }

  const deleteFunction = async () => {
    const confirm = window.confirm("Are you sure you want to delete this section?");
    if (!confirm) {
      return;
    }

    try {
      await api.delete(`/company/section?session_id=${session_id}&section_id=${data._id}`);
      populateSections();
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  const addStage = async (value) => {
    try {
      const api_call = await api.post(`/company/section/stages?session_id=${session_id}&section_id=${data._id}`, { stage_name: value });
      setStages([ ...stages, api_call.data.stage ]);
      setShowPopup(false);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    setStages(data.stages || []);
  }, [data]);

  return (
    <div ref={ref} className="stage_section__grp">

      {(showPopup) && (
        <Popup
          key={1}
          cancelFnc={() => setShowPopup(false)}
          type="stage"
          action="add"
          returnFun={addStage}
        />
      )}

      <div className="stage_section__wrapper">
        <div className="stage_section__grp__info">
          <h4>section: </h4>
          <p>{data?.title}</p>
        </div>

        <div className="stage_section__grp__controls">
          <button className="show-stages" onClick={activateBtn}>Show Stages <i className="uil uil-angle-down"></i></button>

          <button className="stage-btn hamburger" onClick={() => setShowPopup(true)} >
            <i className="uil uil-plus"></i>
          </button>

          <button className="stage-btn edit" onClick={editFnc} >
            <i className="uil uil-edit"></i>
          </button>

          <button className="stage-btn remove" onClick={deleteFunction}>
            <i className="uil uil-trash-alt"></i>
          </button>
        </div>
      </div>

      <Stages data={stages} section_id={data?._id} setStages={setStages} />
    </div>
  )
}

export default Section;
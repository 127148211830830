import React, { useEffect, useState } from "react";
import {
  CheckList,
  FixedSalary,
  NumberInput,
  RadioList,
  RangeSalary,
  SelectInput,
  TextAreaInput,
  TextInput,
  CoverLetter,
  SelectionStage
} from "./FormInputs";


function JobDetails({ campaign_data }) {
  return (
    <div className="campaign-form__container">
      <h4 className="campaign-form__container--title">Job Details</h4>
      <div className="form-section">
        <TextInput
          label="Job Profile"
          placeholder="e.g. React Web Developer"
          id="profile"
          default_value={campaign_data?.campaign_title || ""}
          size={60}
        />
        <SelectInput
          label="Skills required"
          placeholder="e.g. Javascript"
          id="skills"
          default_value={campaign_data?.skills_required || []}
          size={10}
        />
        <RadioList
          name="job-type"
          radioInfo={["Office", "Remote", "Hybrid"]}
          id="work_type"
          label="Job Type"
          default_value={campaign_data?.work_type}
        />
        <SelectInput
          label="City/Cities"
          placeholder="e.g. Mumbai"
          id="cities"
          default_value={campaign_data?.locations || []}
          size={5}
        />
        <RadioList
          name="part-time"
          radioInfo={["Yes", "No"]}
          id="part-time"
          label="Is part time available for this job?"
          default_value={campaign_data?.part_time?0:1}
        />
        <NumberInput
          label="Number of Openings"
          placeholder="e.g. 4"
          id="openings"
          default_value={campaign_data?.vacancies || ""}
        />
        <NumberInput
          label="Minimum Experience (yrs)"
          placeholder="e.g. 2"
          id="min-exp"
          default_value={campaign_data?.min_experience || ""}
        />
        <TextAreaInput
          label="Job Description"
          id="responsibilities"
          preloaded_msg={["Key responsibilities: ", "", "1. ", "2. ", "3. "]}
          default_value={campaign_data?.responsibility || ""}
          size={2000}
        />
        <TextAreaInput
          label="Do you have any candidate preferences?"
          id="preference"
          placeholder="e.g. Only Computer Science Graduates"
          optional={true}
          default_value={campaign_data?.preference || ""}
          size={500}
        />
        <SelectionStage label="Selection Stage" default_value={campaign_data?.section_id} />
      </div>
    </div>
  );
}

function Salary({ campaign_data }) {
  const [probation, setProbation] = useState(false);
  const [salary_type, setSalaryType] = useState("fixed");

  useEffect(() => {
    if (campaign_data?.ctc_type === 0) {
      setSalaryType("fixed");
    }
    else if (campaign_data?.ctc_type === 1) {
      setSalaryType("unfixed");
    }

    setProbation(campaign_data?.probation || false);
  }, [campaign_data]);

  return (
    <div className="campaign-form__container">
      <h4 className="campaign-form__container--title">Salary & perks</h4>
      <div className="form-section">
        <div className="form-div">
          <h4 className="form-input-heading">CTC Type</h4>
          <div className="form-horizontal border-line">
            <div className="form-group">
              {(salary_type === "fixed") ? 
                (<input
                  type="radio"
                  id="salary1"
                  name="salary"
                  defaultChecked
                  onChange={() => setSalaryType("fixed")}
                />) :
                (<input
                  type="radio"
                  id="salary1"
                  name="salary"
                  onChange={() => setSalaryType("fixed")}
                />)
              }
              <label htmlFor="salary1">Fixed</label>
            </div>
            <div className="form-group">
              {(salary_type === "unfixed") ? 
                (<input
                  type="radio"
                  id="salary2"
                  name="salary"
                  defaultChecked
                  onChange={() => setSalaryType("unfixed")}
                />) :
                (<input
                  type="radio"
                  id="salary2"
                  name="salary"
                  onChange={() => setSalaryType("unfixed")}
                />)
              }
              <label htmlFor="salary2">Negotiable</label>
            </div>
          </div>
        </div>


        <div id="salary">
          {salary_type === "fixed" && (
            <FixedSalary
              label="CTC (Annually)"
              id="job-salary"
              showType={false}
              default_value={campaign_data?.ctc?.amt}
              placeholder="e.g  200000" />
          )}

          {salary_type === "unfixed" && (
            <RangeSalary
            placeholder1="e.g  200000"
            placeholder2="e.g  250000"
            duration={false}
            label="CTC (LPA)"
            default_value={campaign_data?.ctc}
            id="job-salary"
          />
          )}
          

          <div className="error">
            <i className="uil uil-exclamation-octagon"></i>
            <p className="error--text">This field is required</p>
          </div>
        </div>


        <div id="probation" className="form-div">
          <h4 className="form-input-heading">
            Does this job has a probation period?
          </h4>
          <div className="form-horizontal border-line">
            <div className="form-group">
              {(campaign_data?.probation) ? 
                (<input
                  type="radio"
                  id="probation-yes"
                  name="probation"
                  onChange={() => setProbation(true)}
                  defaultChecked
                />) :
                (<input
                  type="radio"
                  id="probation-yes"
                  name="probation"
                  onChange={() => setProbation(true)}
                />)
              }
              <label htmlFor="probation-yes">Yes</label>
            </div>
            <div className="form-group">
              {(!(campaign_data?.probation)) ? 
                (<input
                  type="radio"
                  id="probation-no"
                  name="probation"
                  onChange={() => setProbation(false)}
                  defaultChecked
                />) :
                (<input
                  type="radio"
                  id="probation-no"
                  name="probation"
                  onChange={() => setProbation(false)}
                />)
              }
              <label htmlFor="probation-no">No</label>
            </div>
          </div>

          <div className="error">
            <i className="uil uil-exclamation-octagon"></i>
            <p className="error--text">This field is required</p>
          </div>
        </div>

        {probation && (
          <NumberInput
            label="Probation duration (Months)"
            placeholder="e.g. 4"
            id="probation-duration"
            default_value={campaign_data?.probation_duration || ""}
          />
        )}

        <CheckList
          label="Perks"
          optional={true}
          id="perks"
          checks={[["5 days a week", "Life Insurance"], ["Health Insurance"]]}
          default_checks={campaign_data?.perks || []}
        />

      </div>
    </div>
  );
}

function JobForm({ submitFunc, mode, campaign_data }) {
  return (
    <>
      <JobDetails campaign_data={campaign_data} />
      <Salary campaign_data={campaign_data} />
      <CoverLetter campaign_data={campaign_data} que_size={300} />

      {/* Form Buttons */}
      <div className="campaign-form__buttons">
        <button className="campaign-form__buttons--create" onClick={submitFunc}>
          <i className="uil uil-rocket" />
          {mode === "create" ? "Create New" : "Update"}
        </button>
      </div>
    </>
  );
}

export default JobForm;

import React from "react";
import "../../../css/Pages/company_pages/campaigns/card.css";
import DropDown from "./DropDown";


function Card({ data, upward_menu, activateForm, showApplication, verifyCampaign=false }) {
  const convertDate = (date_num) => {
    const date_split = (new Date(date_num)).toDateString().split(" ");
    return `${date_split[2]} ${date_split[1]} ${date_split[3]}`
  }

  let classToAdd = "verification";
  if (!verifyCampaign) {
    classToAdd = data.campaign_type === 0? "internship": "job";
  }

  return (
    <div className={"campaign-card " + classToAdd}>
      <div className="campaign-card__container">
        {verifyCampaign && <div className="campaign-card__container--approval">
          <i className="uil uil-clock"></i>
          <span>Waiting for admin approval</span>
        </div> }

        <div className="row">
          <div className="campaign-card__container--info">
            <h4>Type: </h4>
            <p>{data?.campaign_type === 0? "Internship": "Job"}</p>
          </div>
        </div>

        <div className="row">
          <div className="campaign-card__container--info">
            <h4>Title: </h4>
            <p>{data?.campaign_title}</p>
          </div>
        </div>
        
        {!verifyCampaign && <div className="row grid-layout">

          <div className="campaign-card__container--info">
            <h4>Expirary: </h4>
            <p>{convertDate(data?.expiry_date)}</p>
          </div>

          <div className="campaign-card__container--info end-align">
            <h4>Vacancy: </h4>
            <p>{data?.vacancies}</p>
          </div>

          <div className="campaign-card__container--info">
            <h4>Total Views: </h4>
            <p>{data?.views}</p>
          </div>

          <div className="campaign-card__container--info end-align">
            <h4>Hired: </h4>
            <p>{data?.hired}</p>
          </div>
        </div>}
      </div>

      { !verifyCampaign && <div className="campaign-card__buttons">
        <DropDown upward_menu={upward_menu} active={data?.status} campaign_id={data?._id} />
        <button className="campaign-card__buttons--view" onClick={() => showApplication(data)}>
          View Applications ({data?.applications?.length})
        </button>
        <button className="campaign-card__buttons--update" onClick={() => activateForm(data?._id)}>Update</button>
      </div> }
    </div>
  );
}

export default Card;

import React, { useEffect, useState } from "react";
import Section from "./Section";
import Popup from "./Popup";
import "../../../css/Pages/company_pages/stages/main.css";
import { api } from "../../../backend";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";


const LOADING_GIF = process.env.REACT_APP_LOADING_IMG;
const SECTION_NOTHING_IMG = process.env.REACT_APP_SECTION_NOTHING_IMG;


function Main() {
  const navigate = useNavigate();
  const session_id = localStorage.getItem("session_id");
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showPopup, setShowPopup] = useState({ visible: false });


  const populateSections = async () => {
    setLoading(true);
    try {
      const api_call = await api.get(`/company/section/all?session_id=${session_id}`);
      setSections(api_call.data.sections);
      setLoading(false);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }


  const createSection = async (value) => {
    setShowPopup({ visible: false });
    setLoading(true);
    try {
      await api.post(`/company/section?session_id=${session_id}`, { title: value });
      populateSections();
    } catch (err) {
      alert(err.response.data.msg);
    }
  }


  const updateSection = async (value) => {
    setShowPopup({ visible: false });
    setLoading(true);
    try {
      await api.put(`/company/section?session_id=${session_id}&section_id=${showPopup.section_id}`, { title: value });
      populateSections();
    } catch (err) {
      alert(err.response.data.msg);
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    populateSections();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="stage_section">
      <div className="stage_section__head">
        <h2 className="stage_section__head--heading">Sections Manager</h2>
        <button
          className="stage_section__head--create"
          onClick={() => setShowPopup({ type: "section", action: "create", visible: true})}
        >Create Sections</button>
      </div>

      {showPopup?.visible && (
        <Popup
          key={1}
          cancelFnc={() => setShowPopup({ visible: false })}
          type="section"
          action={showPopup?.action}
          value={showPopup?.value}
          returnFun={showPopup?.action==="create"?createSection:updateSection}
        />
      )}

      <div className="stage_section__body">
        { sections.map((sec) => (
          <Section
            key={uuidv4()}
            data={sec}
            populateSections={populateSections}
            editFnc={() => setShowPopup({action: "update", value: sec.title, visible: true })}
          />
        ))}
      </div>

      {(!loading && sections.length === 0) && (
        <div className="nothing-found">
          <img src={
            (SECTION_NOTHING_IMG) + "?tr=h-280,w-280"
          } alt="nothing-found" />
          <h1>Nothing found</h1>
        </div>
      )}

      {loading && (
        <div className="loading-gif">
          <img src={LOADING_GIF + "?tr=w-100,h-100"} alt="" />
        </div>
      )}
    </div>
  );
}

export default Main;

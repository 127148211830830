import React, { useEffect, useRef, useState } from "react";
import { api } from "../../../backend";
import "../../../css/Pages/company_pages/profile/main.css";
import Popup from "./Popup";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";


const LOADING_IMG = process.env.REACT_APP_LOADING_IMG;
const DEFAULT_IMG = process.env.REACT_APP_COMPANY_DEFAULT_PROFILE;


function Main() {
  const navigate = useNavigate();
  const session_id = localStorage.getItem("session_id");
  const session_type = localStorage.getItem("session_type");

  const [data, setData] = useState({});
  const [aboutLoad, setAboutLoad] = useState(false);
  const [profileLoad, setProfileLoad] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const fileUpload = useRef(null);
  const [popup, setPopup] = useState(false);

  const selectImage = () => {
    if (profileLoad || session_type === "helper") {
      return
    }
    fileUpload.current.click();
  }

  const uploadImage = async () => {
    const file = fileUpload.current.files[0];

    if (!file) {
      return;
    }
    const fileName = file?.name;
    const fileSize = file?.size;
    let extension = fileName?.split(".");
    extension = extension[extension.length - 1];

    if ((fileSize/1000/1000) > 1 ) {
      alert("File size must be smaller than 1mb");
      return;
    }

    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      alert("jpg, jpeg, png extension only");
      return;
    }

    document.getElementById("image").src  = window.URL.createObjectURL(file)
    setProfileLoad(true);
    document.querySelector(".company__profile__info--image").classList.remove("show-add")

    // Uploading ...
    const formData = new FormData();
    formData.append('file', file);

    try {
      await api.post(`/upload/company/image?session_id=${session_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }

    setProfileLoad(false);
  }

  const populateData = async () => {
    try {
      const api_call = await api.get(`/register/company/search-id?session_id=${session_id}`);
      const api_call_profile = await api.get(`/profile/company/public?comp_id=${api_call.data.company._id}`);

      if (session_type==="helper") {
        const api_call_helper = await api.get(`/register/helper?session_id=${session_id}`);
        setData({ ...(api_call.data.company), ...(api_call_profile.data.company), ...{
          comp_email: api_call_helper.data.helper.helper_email,
          admin_name: api_call_helper.data.helper.helper_name,
        }});
      } else {
        setData({ ...(api_call.data.company), ...(api_call_profile.data.company) });
      }
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  const updateAbout = async () => {
    try {
      await api.put(`/profile/company?session_id=${session_id}`, {
        about: document.getElementById("about")?.value
      });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  const logoutApi = async () => {
    const confirm = window.confirm("Are you sure you want to log out?");
    if (!confirm) return;

    try {
      await api.delete(`/logout?session_id=${localStorage.getItem("session_id")}`);
      localStorage.removeItem("session_id");
      localStorage.removeItem("session_type");
      navigate("/login");
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        navigate("/");
        alert(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    if (imageUrl === "" && data?.comp_logo) {
      setProfileLoad(true);
      fetch(process.env.REACT_APP_IMAGE_SERVER + data.comp_logo)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
          setProfileLoad(false);
        });

      return () => {
        // Clean up the temporary URL
        URL.revokeObjectURL(imageUrl);
      };
    } else {
      setProfileLoad(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
    populateData();

    const about = document.getElementById("about");
    about?.addEventListener("input", (e) => {
      setAboutLoad(true);
      // removing all timeouts
      let id = window.setTimeout(function() {}, 0);
      while (id--) {
        window.clearTimeout(id);
      }

      const target = e.currentTarget;

      if (target.value.length <= 1200) {
        setTimeout(() => {
          setAboutLoad(false);
          updateAbout();
        }, 5000);
      }

      if (target.value.length > 1200) {
        target.value = target.value.slice(0, 1200);
      }


      const stats = target.parentElement.querySelector(".headline-stats span");
      stats.innerText = about.value.length;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      { session_type!=="helper" && popup && (
        <Popup
          hidePopup={() => setPopup(false)}
          id={popup?.id}
          data={data}
          populateData={populateData}
        />
      )}

      <div className="company__profile">
        <div className="company__profile__info">
          <div className="company__profile__info--image"
            onMouseEnter={(e) => {
              if (profileLoad) return;
              e.currentTarget.classList.add("show-add")
            }}
            onMouseLeave={(e) => {
              if (profileLoad) return;
              e.currentTarget.classList.remove("show-add")
            }}
            onClick={selectImage}
          >
            <img src={(imageUrl || (
              DEFAULT_IMG + "?tr=h-120,w-120"
            ))} className={profileLoad?" background":""} id="image" alt="profile" />
            { session_type !== "helper" && <i className="uil uil-plus-circle" />}
            { session_type !== "helper" && (
              <input type="file" ref={fileUpload} onChange={uploadImage} accept="image/png, image/jpg, image/jpeg" style={{ display: "none" }} />
            )}
            { profileLoad && <div className="profile-load">
              <img src={LOADING_IMG + "?tr=h-30,w-30"} alt="loading" />
            </div> }
          </div>

          <h4 className="company__profile__info--name">{data?.admin_name}</h4>

          <div className="company__profile__info--box">
            <i className="uil uil-envelope-alt" />
            <p>{data?.comp_email}</p>
          </div>

          <div className="company__profile__info--box">
            <i className="uil uil-phone" />
            <p>{data?.comp_mobile}</p>
          </div>

          {(data?.state || data?.city) && <div className="company__profile__info--box">
            <i className="uil uil-map-marker" />
            <p>{data?.state || data?.city}</p>
          </div>}
          
          {data?.website && (
            <div className="company__profile__info--box">
              <i className="uil uil-link-h" />
              <a href={data?.website} target="_blank" rel="noreferrer" >Website</a>
            </div>
          )}

          { session_type!=="helper" && (
            <button className="company__profile__info--btn update" onClick={() => setPopup(true)}>Update Profile</button>
          )}
          <button className="company__profile__info--btn logout" onClick={logoutApi}>Logout</button>
        </div>

        <div className="user-profile__details no-margin">
          <div className="resume-headline normal-border">
            <h4 className="normal-border--title">
              <span>About {data?.comp_name}</span>
              { session_type!=="helper" && (
                <>
                  {aboutLoad?<img src={LOADING_IMG + "?tr=h-20,w-20"} alt="loading" />:<i className="uil uil-check" />}
                </>
              )}
            </h4>
            { session_type!=="helper" && (<p className="normal-border--info">
              Tell the job seekers what your business do and how can they get value from joining your organisation
            </p>)}

            { session_type!=="helper" && (<textarea
              className="resume-headline--text"
              placeholder="Write Something..."
              defaultValue={data?.about}
              id="about"
            />)}

            { session_type==="helper" && (
              <div style={{ marginTop: "1rem"}}>
                {data?.about?.split("\n").map((word) => (
                  <React.Fragment key={uuidv4()}>
                    {word}<br/>
                  </React.Fragment>
                ))}
              </div>
            )}

            { session_type!=="helper" && (
              <div className="headline-stats"><span>{data?.headline?.length || 0}</span>/1200</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;

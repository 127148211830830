import React from "react";
import "../../../css/Pages/company_pages/helpers/section.css";
import Card from "./Card";
import { v4 as uuidv4 } from 'uuid';


function Section({ title, allowed, helpers = [], createPopup, updatePopup, removeFunc }) {

  const isDbEmpty = helpers.length === 0;

  return (
    <div className="helper-section">
      <h1 className="section__heading">
        {title}  <span>({allowed - helpers.length})</span>
      </h1>

      <div className={"section__adding" + (isDbEmpty?" empty": "") }>
        {helpers?.map((helper) => (
          <Card
            key={uuidv4()}
            name={helper?.helper_name}
            email={helper?.helper_email}
            removeFunc={() => removeFunc(helper?._id)}
            updatePopup={() => updatePopup({
              name: helper?.helper_name,
              email: helper?.helper_email,
              helper_id: helper?._id
            })}
          />
        ))}

        { isDbEmpty ? <p className="section__adding--text">No Manager Added</p> : "" }

        {helpers.length < allowed? (
          <button className="section__adding--btn" onClick={createPopup}>Add Manager</button>
        ) : "" }
      </div>
    </div>
  );
}

export default Section;

import React, { useEffect, useState } from "react";
import Filters from "./Filters";
import Candidate from "./Candidate";
import "../../../css/Pages/company_pages/market-place/main.css";
import { api } from "../../../backend";
import { useNavigate } from "react-router-dom";

const LOADING_GIF = process.env.REACT_APP_LOADING_IMG;
const CAMPAIGN_NOTHING_IMG = process.env.REACT_APP_CAMPAIGN_NOTHING_IMG;

const Page_Size = 15;
let Page_Number = 0;

function Main() {
  let filters = {};
  const [seekers, setSeekers] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const session_id = localStorage.getItem("session_id");
  const navigate = useNavigate();


  const populateSeeker = async (set_filters) => {
    Page_Number += 1;

    if (set_filters) {
      console.log("HERE");
      filters = set_filters;
      Page_Number = 1;
    }

    setLoading(true);
    setLoadMore(false);

    try {
      const api_call = await api.post(
        `/company/market-place?page_num=${Page_Number}&page_size=${Page_Size}&session_id=${session_id}`, {
        ...(set_filters || filters)
      });

      setLoading(false);
      const seekers_length = api_call?.data?.job_seekers.length;
      if (( seekers_length === 0) || (seekers_length < Page_Size)) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }

      if (set_filters) {
        setSeekers([...(api_call?.data?.job_seekers)]);
      } else {
        setSeekers([...(seekers), ...(api_call?.data?.job_seekers)]);
      }
      document.querySelector(".close-icon").click()
    }
    catch (err) {
      setLoading(false);
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    populateSeeker();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeFilter = () => {
    document.body.classList.add("fixed");
    setFilterActive(true);
  }

  const deactiveFilter = () => {
    document.body.classList.remove("fixed");
    setFilterActive(false);
  }

  const grayTouch = (e) => {
    if (e.target.classList.contains("gray-section")) {
      setFilterActive(false);
    }
  }

  return (
    <div className="market">
      <div className="filters-mob market-place">
        <h4 className="filters__heading" onClick={activeFilter}>
          <i className="uil uil-filter"></i> Filters
        </h4>
      </div>
      <div className="market__head">
        {/* <h2 className="market__head--title">Market Place</h2> */}
        {/* <button className="market__head--filters">Filters</button> */}
      </div>

      {/* <div className="nothing-found">
        <img src={
          COMING_SOON_IMG + "?tr=h-280,w-280"
        } alt="nothing-found" />
        <h1>Feature coming soon</h1>
      </div> */}

      <div className="market__body">
        {
          seekers.map((seeker, i) => (
            <Candidate key={i} data={seeker} />
          ))
        }

        <div className="gray-section" onClick={grayTouch} style={{ display: filterActive?"grid":"none"}}>  
          <Filters deactiveFilter={deactiveFilter} populateSeeker={populateSeeker} />
        </div>

        {(!loading && seekers?.length === 0) && (
          <div className="nothing-found">
            <img src={
              CAMPAIGN_NOTHING_IMG + "?tr=h-280,w-280"
            } alt="nothing-found" />
            <h1>Nothing found</h1>
          </div>
        )}

        {loading && (
          <div className="loading-gif">
            <img src={LOADING_GIF + "?tr=w-100,h-100"} alt="" />
          </div>
        )}

        {loadMore && (
          <button className="load-more--btn" onClick={() => populateSeeker()}>
            Load More
          </button>
        )}
      </div>
    </div>
  );
}

export default Main;

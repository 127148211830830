import React, { useEffect, useRef } from "react";
import "../../../css/Pages/company_pages/helpers/popup.css";

function Popup({ hidePopup, info, update=false, createFunc, updateFunc  }) {
  const name = useRef(null);
  const email = useRef(null);
  const password = useRef(null);

  const submitFunc = () => {
    const error = document.querySelector(".error");

    const name_val = name?.current?.value;
    const email_val = email?.current?.value;
    const password_val = password?.current?.value

    if (update) {
      updateFunc({ name: name_val, email: email_val, password: password_val });
    } else {
      if (!name_val) {
        error.classList.add("active");
        error.innerText = "Please enter a name";
        name.current.focus();
        return;
      }

      if (!email_val) {
        error.classList.add("active");
        error.innerText = "Please enter an email";
        email.current.focus();
        return;
      }

      if (!password_val) {
        error.classList.add("active");
        error.innerText = "Please enter password";
        password.current.focus();
        return;
      }

      createFunc({name: name_val, email: email_val, password: password_val});
    }
  }

  useEffect(() => {
    const blackScreen = document.querySelector(".popup-blackscreen");
    blackScreen.addEventListener("click", (e) => {
      if (e.target.classList.contains("popup-blackscreen")) {
        hidePopup();
      }
    })
  }, [hidePopup])

  return (
    <div className="popup-blackscreen">
      <div className="popup-box">

        <div className="text-fields">
          <input type="text" ref={name} placeholder="Full Name" defaultValue={info?.name || ""} />
          <input type="email" ref={email} placeholder="Email Address" defaultValue={info?.email || ""} />

          <div className="pass-field">
            <input type="text" ref={password} placeholder={update?"New Password":"Password"} />
          </div>
        </div>

        <p className="error popup-error" style={{ marginTop: 0 }}>Next Field Empty</p>

        <div className="btns-container">
          <button className="btns--create" onClick={submitFunc}>{update?"Update":"Add"}</button>
          <button className="btns--cancel" onClick={hidePopup}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default Popup;

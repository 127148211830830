import React, { useEffect, useRef, useState } from "react";
import "../../../css/Pages/job_seeker/profile/userInfo.css";
import { api } from "../../../backend";
import { useNavigate } from "react-router-dom";

const DEFAULT_IMG = process.env.REACT_APP_USER_DEFAULT_PROFILE;
const LOADING_IMG = process.env.REACT_APP_LOADING_IMG;

function UserInfo({ data, setPopup }) {
  const navigate = useNavigate();
  const session_id = localStorage.getItem('session_id');
  if (!session_id) {
    navigate("/login");
  }

  const fileUpload = useRef(null);
  const [profileLoad, setProfileLoad] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [resumeLink, setResumeLink] = useState("");


  const logoutFunction = async (e) => {
    const proceed = window.confirm("Are you sure you want to logout?");
    if (!proceed) {
      return;
    }

    if (e.currentTarget.classList?.contains("disabled")) {
      return;
    }

    e.currentTarget.classList?.add("disabled");

    try {
      await api.delete(`/logout?session_id=${session_id}`);
      localStorage.removeItem('session_id');
      localStorage.removeItem('session_type');
      navigate("/");
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      }
      else {
        navigate("/");
        alert(err.response?.data.msg);
      }
    }
  };

  const imageHover = (e) => {
    if (profileLoad) {
      return
    }
    e.currentTarget.classList?.add("hover");
  }

  const imageUnHover = (e) => {
    if (profileLoad) {
      return
    }
    e.currentTarget.classList?.remove("hover");
  }

  const selectImage = () => {
    if (profileLoad) {
      return
    }
    fileUpload.current?.click();
  }

  const uploadImage = async () => {
    const file = fileUpload.current?.files[0];

    if (!file) {
      return;
    }
    const fileName = file?.name;
    const fileSize = file?.size;
    let extension = fileName?.split(".");
    extension = extension[extension.length - 1];

    if ((fileSize/1000/1000) > 1 ) {
      alert("File size must be smaller than 1mb");
      return;
    }

    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      alert("jpg, jpeg, png extension only");
      return;
    }

    const imageWrapper = document.querySelector(".image--wrapper");
    imageWrapper?.classList.remove("hover");

    const imageObj = imageWrapper.querySelector("img");
    if (imageObj) {
      imageObj.src  = window.URL.createObjectURL(file)
    }
    setProfileLoad(true);

    // Uploading ...
    const formData = new FormData();
    formData.append('file', file);

    try {
      await api.post(`/upload/job-seeker/image?session_id=${session_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }

    setProfileLoad(false);
  }

  useEffect(() => {
    setResumeLink(data?.resume);

    if (imageUrl === "" && data?.profile) {
      setProfileLoad(true);
      fetch(process.env.REACT_APP_IMAGE_SERVER + data.profile)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
          setProfileLoad(false);
        });

      return () => {
        // Clean up the temporary URL
        URL.revokeObjectURL(imageUrl);
      };
    } else {
      setProfileLoad(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="user-profile__info">
      <div className="image--wrapper" onClick={selectImage} onMouseEnter={imageHover} onMouseLeave={imageUnHover}>
        <img
          src={imageUrl || DEFAULT_IMG}
          alt="profile_photo"
          className="profile-photo"
        />
        <i className="uil uil-plus-circle" />
        <input type="file" ref={fileUpload} style={{ display: "none" }} accept="image/*" onChange={uploadImage} />

        { profileLoad && <div className="profile-load">
          <img src={LOADING_IMG + "?tr=h-30,w-30"} alt="loading" />
        </div> }
      </div>

      <div className="wrapper">
        <h2 className="profile__name">{data?.full_name}</h2>
        <div className="profile__info-div">
          <div className="info-box">
            <i className="uil uil-envelope-alt" />
            <p>{data?.email}</p>
          </div>

          <div className="info-container">
            <div className="info-wrapper">
              <div className="info-box">
                <i className="uil uil-phone" />
                <p> {(data?.mobile)?"+91 " + data.mobile:"N/A"}</p>
              </div>

              <div className="info-box">
                <i className="uil uil-map-marker" />
                <p>{(data?.state)?data.state:"Not Saved"}</p>
              </div>
            </div>

            <div className="info-wrapper">
              <div className="info-box">
                <i className="uil uil-exchange" />
                <p>{(data?.notice_period)?data.notice_period + " Month(s)": "Available Now"} </p>
              </div>

              { (resumeLink) && <div className="info-box">
                <i className="uil uil-postcard" />
                <a href={process.env.REACT_APP_RESUME_SERVER + resumeLink} target="_blank" rel="noreferrer">
                  Resume
                </a>
              </div>}
            </div>
          </div>

        </div>
      </div>

      <div className="profile__btns">
        <button className="update--profile" onClick={() => setPopup({
          state: true,
          type: "info",
          data: data,
          resumeLink: resumeLink,
          setResumeLink: setResumeLink
          })}
        >
          Update Profile
        </button>
        <button className="logout--profile" onClick={logoutFunction}>Logout</button>
      </div>
    </div>
  );
}

export default UserInfo;

import React, { useEffect, useState } from "react";
import "../../../css/Pages/company_pages/campaigns/campaign_form.css";
import "../../../css/Pages/company_pages/campaigns/campaign_application.css";
import Candidate from "./Candidate";
import { api } from "../../../backend";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";


const LOADING_GIF = process.env.REACT_APP_LOADING_IMG;
const APPLICATION_NOTHING_IMG = process.env.REACT_APP_APPLICATION_NOTHING_IMG;


function CampaignApplication({ hideFunc, campaign_id, getTitle, section_id }) {
  const session_id = localStorage.getItem("session_id");
  const navigate = useNavigate();

  const [filter, setFilter] = useState("all");
  const [applications, setApplications] = useState([]);
  const [sortApplications, setSortApplications] = useState([]);
  const [stages, setStages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [appStats, setAppStats] = useState({});

  function deactivateForm() {
    const mainScrollArea = document.querySelector(".main-area__scroll");
    mainScrollArea.classList.remove("freeze");
    hideFunc();
  }

  const popuplateApplications = async () => {
    setLoading(true);
    try {
      const api_call = await api.get(`/company/application/all?campaign_id=${campaign_id}&session_id=${session_id}`);
      setLoading(false);
      setApplications(api_call.data.application);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  const popuplateSection = async () => {
    try {
      const api_call = await api.get(`/company/section?section_id=${section_id}&session_id=${session_id}`);
      setStages(api_call.data.section?.stages);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  const isStageIdPresent = (stage_id) => {
    for (let stage of (stages || [])) {
      if (stage.stage_id === (stage_id || "")) {
        return true;
      }
    }
    return false;
  }

  const filterCampaign = () => {
    if (applications.length === 0) return;
    const filtered = [];
    const newAppStats = {};

    for (let app of applications) {
      if (
        (!(app.user_application.stage_id) &&
        !(app.user_application.hired) &&
        !(app.user_application.short_listed) &&
        !(app.user_application.rejected)) ||
        (app.user_application.stage_id && !isStageIdPresent(app.user_application.stage_id))
      ) {
        newAppStats.all = (newAppStats.all || 0)+1
      }
      else if (app.user_application.hired) {
        newAppStats.hired = (newAppStats.hired || 0)+1
      }
      else if (app.user_application.short_listed) {
        newAppStats.shortlisted = (newAppStats.shortlisted || 0)+1
      }
      else if (app.user_application.rejected) {
        newAppStats.rejected = (newAppStats.rejected || 0)+1
      }
      else if(app.user_application.stage_id){
        newAppStats[app.user_application.stage_id] = (newAppStats[app.user_application.stage_id] || 0)+1
      }

      if (filter === "all") {
        if (
          (!(app.user_application.stage_id) &&
          !(app.user_application.hired) &&
          !(app.user_application.short_listed) &&
          !(app.user_application.rejected)) ||
          (app.user_application.stage_id && !isStageIdPresent(app.user_application.stage_id))
        ) {
          filtered.push(app);
        }
      }
      else if (filter === "hired") {
        if (app.user_application.hired) {
          filtered.push(app);
        }
      }
      else if (filter === "short_listed") {
        if (app.user_application.short_listed) {
          filtered.push(app);
        }
      }
      else if (filter === "rejected") {
        if (app.user_application.rejected) {
          filtered.push(app);
        }
      }
      else{
        if (app.user_application.stage_id === filter) {
          filtered.push(app);
        }
      }
    }

    setAppStats(newAppStats)
    setSortApplications(filtered);
  }


  useEffect(() => {
    popuplateSection().then(() => {
      popuplateApplications();
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id])

  useEffect(() => {
    filterCampaign();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applications, filter])


  return (
    <div className="campaign-form">
      <div className="campaign-form__head">
        <button className="campaign-form__head--back normal--button" onClick={deactivateForm}>
          <i className="uil uil-arrow-left" />
          <p>Back</p>
        </button>

        <h2 className="campaign-form__head--heading">Applications</h2>

        <div className="campaign-form__head--select">
        <select onChange={(e) => setFilter(e.currentTarget.value)}>
          <option value="all">All ({appStats.all || 0})</option>
          <option value="hired">Hired ({appStats.hired || 0})</option>
          <option value="short_listed">Short Listed ({appStats.shortlisted || 0})</option>
          <option value="rejected">Rejected ({appStats.rejected || 0})</option>
          {stages?.length>0 && <option disabled style={{ textAlign: "center"}}>------------</option>}
          { stages?.map((stage) => (
            <option value={stage?.stage_id}>{stage?.stage_name} ({appStats[stage?.stage_id] || 0})</option>
          ))}
        </select>
      </div>
      </div>

      <div className="campaign-form__info">
        <div className="campaign-form__info--container">
          <h4 className="info-title">title :</h4>
          <p className="info-text">{getTitle()}</p>
        </div>

        <div className="campaign-form__info--container">
          <h4 className="info-title">Applications Received :</h4>
          <p className="info-text-box">{applications.length}</p>
        </div>
      </div>

      <div className="campaign-form__body">
        {!loading && (sortApplications?.length===0) && (
          <div className="nothing-found">
            <img src={
              APPLICATION_NOTHING_IMG + "?tr=h-280,w-280"
            } alt="nothing-found" />
            <h1>No Applications</h1>
          </div>
        )}

        {loading && (
          <div className="loading-gif">
            <img src={LOADING_GIF + "?tr=w-100,h-100"} alt="" />
          </div>
        )}

        <div className="application__container">
          { stages !== null && sortApplications?.map((app) => 
            <Candidate
              user_data={app.user_info}
              application_data={app.user_application}
              key={uuidv4()}
              hide={!app.user_application.viewable}
              stages={stages}
              filter={filter}
              popuplate={popuplateApplications} />
          )}
        </div>
      </div>
    </div>
  );
}

export default CampaignApplication;
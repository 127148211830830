import React, { useEffect, useRef, useState } from "react";
import "../../../css/Pages/company_pages/market-place/candidate.css";
import { api } from "../../../backend";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";

const DEFAULT_IMG = process.env.REACT_APP_USER_DEFAULT_PROFILE;
const IMAGE_SERVER = process.env.REACT_APP_IMAGE_SERVER;
const RESUME_SERVER = process.env.REACT_APP_RESUME_SERVER;

function Candidate({ user_data, application_data={}, hide=false, stages, popuplate, market=false }) {
  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState("");
  const [education, setEducation] = useState([]);
  const experience = user_data?.experience || [];
  const sliced_skill = user_data?.key_skills?.slice(0, 6);
  const [socialAvaialbe, setSocialAvaialbe] = useState(false);
  const session_id = localStorage.getItem("session_id");
  const candidate = useRef(null);
  const status = {
    hired: application_data.hired,
    rejected: application_data.rejected,
    short_listed: application_data.short_listed,
    stage_id: application_data.stage_id,
  };

  const [fullCoverLetter, setFullCoverLetter] = useState(false);
  const cover_letter = application_data.cover_letter.split("\n").join("");

  useEffect(() => {
    const sorted_edu = [];
    for (let edu of user_data?.education) {
      if (edu.filled) {
        if (edu.id === 0) {
          if (edu.school) {
            sorted_edu.unshift({ course: "Class X", duration: edu.duration, institute: edu.school });
          }
        }
        else if (edu.id === 1) {
          if (edu.school) {
            sorted_edu.unshift({ course: "Class XII", duration: edu.duration, institute: edu.school });
          }
        }
        else {
          if (edu.college && edu.course) {
            sorted_edu.unshift({ course: edu.course, duration: edu.duration, institute: edu.college });
          }
        }
      }
    }

    setEducation(sorted_edu);

    for (let i of Object.values(user_data?.socials || [])) {
      if (i !== "") {
        setSocialAvaialbe(true);
        break;
      }
    }

    if (imageUrl === "" && user_data?.profile) {
      fetch(IMAGE_SERVER + user_data?.profile)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
        });

      return () => {
        // Clean up the temporary URL
        URL.revokeObjectURL(imageUrl);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_data]);


  const statusApi = async (e, status) => {
    if (e.currentTarget.classList.contains("disabled")) {
      return;
    }

    e.currentTarget.classList.add("disabled")
    try {
      await api.put(
        `/company/application/status?application_id=${application_data.id}&session_id=${session_id}&${status}=true`
      );
      popuplate();
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }


  const stageApi = async (e, stage) => {
    if (e.currentTarget.classList.contains("disabled")) {
      return;
    }

    e.currentTarget.classList.add("disabled")
    try {
      await api.put(
        `/company/application/stage?application_id=${application_data.id}&session_id=${session_id}&stage_id=${stage}`
      );
      popuplate();
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  return (
    <div className="candidate" ref={candidate}>
      <div className="candidate__head">
        <div className="market--wrapper">
          {!hide && <p className="candidate__head--name">{user_data?.full_name}</p>}
          { user_data?.state && (user_data?.relocation || user_data?.relocation === false)  && (
            <p className="candidate__head--location">
              {user_data?.state}
              {user_data?.relocation && (
                <span>
                  <span className="dot" />
                  Open To Relocate
                </span>
              )}
            </p>
          )}
        </div>

        {!hide && (
          <div className="candidate__img">
            <img src={imageUrl || (
              DEFAULT_IMG + "?tr=h-60,w-60"
            )} alt="profile" />
          </div>
        )}
      </div>

      <div className="candidate__body">
        <div className="candidate__info">

          {(experience?.length > 0) && (
            <div className="candidate__info__point">
              <p className="candidate__info__point--main">Experience ({user_data.total_exp} yrs)</p>

              <div className="candidate__desc">
                <div className="candidate__desc__info">
                  <p className="candidate__desc__info--title">{experience[0].job_role}</p>
                </div>

                <div className="candidate__desc__sub">
                  <p className="candidate__desc__sub--name">{experience[0].comp_name}</p>
                  { experience?.length>1 && <p className="candidate__desc__sub--more">+{experience.length-1} more</p>}
                </div>
              </div>
            </div>
          )}

          {(education?.length > 0) && (
            <div className="candidate__info__point">
              <p className="candidate__info__point--main">Education</p>
              <div className="candidate__desc">
                <div className="candidate__desc__info">
                  <p className="candidate__desc__info--title">{education[0]?.course}</p>
                  <span className="dot" />
                  <p className="candidate__exp__info--duration">
                    {education[0].duration?.start.split("/")[2]}-{education[0].duration?.end?.split("/")[2] || "present"}
                  </p>
                </div>

                <div className="candidate__desc__sub">
                  <p className="candidate__desc__sub--name">{education[0]?.institute}</p>
                  {education.length>1 && <p className="candidate__desc__sub--more">+{education.length-1} more</p> }
                </div>
              </div>
            </div>
          )}


          {(sliced_skill?.length > 0) && (
            <div className="candidate__info__point">
              <p className="candidate__info__point--main">Skills</p>

              <div className="candidate__desc">
                <div className="candidate__desc__info">
                  {sliced_skill?.map((skill, i) => {
                    if (i===(sliced_skill.length-1)) {
                      return <p key={uuidv4()} className="candidate__desc--skill">{skill}</p>
                    }
                    else {
                      return <React.Fragment key={uuidv4()}>
                        <p className="candidate__desc--skill">{skill}</p>
                        <div className="line" />
                      </React.Fragment>
                    }
                  })}
                </div>

                {(user_data?.key_skills.length>6) && <div className="candidate__desc__sub">
                  <p className="candidate__desc__sub--more">+{(user_data?.key_skills.length)-6} more</p>
                </div>}
              </div>
            </div>
          )}

          {!hide && socialAvaialbe && (
            <div className="candidate__info__point">
              <p className="candidate__info__point--main">Socials</p>

              <div className="candidate__desc  flex">
                {user_data?.socials?.portfolio && (
                  <a href={user_data?.socials?.portfolio} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-pagelines tooltip">
                      <span className="tooltiptext">Protfolio</span>
                    </i>
                  </a>
                )}

                {user_data?.socials?.linkedin && (
                  <a href={user_data?.socials?.linkedin} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-linkedin tooltip">
                      <span className="tooltiptext">LinkedIn</span>
                    </i>
                  </a>
                )}

                {user_data?.socials?.github && (
                  <a href={user_data?.socials?.github} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-github tooltip">
                      <span className="tooltiptext">GitHub</span>
                    </i>
                  </a>
                )}

                {user_data?.socials?.youtube && (
                  <a href={user_data?.socials?.youtube} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-youtube tooltip">
                      <span className="tooltiptext">Youtube</span>
                    </i>
                  </a>
                )}

                {user_data?.socials?.blog && (
                  <a href={user_data?.socials?.blog} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-blogger tooltip">
                      <span className="tooltiptext">Blog</span>
                    </i>
                  </a>
                )}
              </div>
            </div>
          )}

          {(cover_letter.length > 0) && (
            <div className="candidate__info__point">
              <p className="candidate__info__point--main">Cover Letter</p>

              <div className="candidate__desc">
                <div className="candidate__desc__info">
                  <p className="candidate__desc__info--title">{cover_letter.slice(0, fullCoverLetter?(cover_letter.length):300)}...</p>
                </div>

                <div className="candidate__desc__sub">
                  { cover_letter.length > 300 && (
                    <button
                      className="candidate__desc__sub--more blue"
                      style={{ textDecoration: "underline"}}
                      onClick={() => setFullCoverLetter(!fullCoverLetter)}
                    >
                      View {fullCoverLetter?"less":"more"}</button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="candidate__foot">
        <div className="candidate__foot--div">
          <a className="candidate__foot--view gray-btn" href={`/my-account/application/${application_data.id}`} target="_blank" rel="noreferrer">Details</a>
          { user_data?.resume && (
            <a className="candidate__foot--view blue-btn" href={RESUME_SERVER+(user_data?.resume)} target="_blank" rel="noreferrer">Resume</a>
          )}
        </div>

        <div className="candidate__foot--div">
          { !status.hired && !status.rejected && (
            <button onClick={(e) => statusApi(e, "rejected")} className="candidate__foot--view red-btn">Reject</button>
          )}
          { !status.hired && (
            <button onClick={(e) => statusApi(e, "hired")} className="candidate__foot--view green-btn">Hire</button>
          )}
          { !status.hired && !status.short_listed && (
            <button onClick={(e) => statusApi(e, "shortlisted")} style={{ marginRight: "1rem"}} className="candidate__foot--view blue-btn">Short List</button>
          )}

          {!status.hired && status && (
            <div
              className="candidate__foot--dialog"
              onMouseEnter={(e) => e.currentTarget.classList.add("show-dialog")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("show-dialog")}
            >
              <p>Send To <i className="uil uil-caret-right" /></p>
              <ul className="candidate__foot--dialog-box">
                {stages.map((stage, i) => {
                  if (stage.stage_id !== status.stage_id) {
                    return (
                      <li key={i} onClick={(e) => stageApi(e, stage.stage_id)}>
                        {stage.stage_name}
                      </li>
                    )
                  }
                  else {
                    return <></>
                  }
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Candidate
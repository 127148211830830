import React, { useEffect, useState } from "react";
import "../../../css/Pages/company_pages/campaigns/campaign_form.css";
import InternshipForm from "./InternshipForm";
import JobForm from "./JobForm";
import { api } from "../../../backend";
import { useNavigate } from "react-router-dom";


function CampaignForm({ setVisibile, mode = "update", populateCampaigns, campaign_id }) {
  const session_id = localStorage.getItem("session_id");
  const [formType, setFormType] = useState("job");
  const [campaign_data, setCampaignData] = useState({});
  const navigate = useNavigate();

  const fillCampaignData = async () => {
    try {
      const api_call = await api.get(`/company/campaign/public?campaign_id=${campaign_id}&detailed=true`);
      const campaign = api_call.data.campaign;
      const details = api_call.data.details;
      setCampaignData({...campaign, ...details});

      if (campaign.campaign_type === 0) {
        setFormType("intern");
      } else {
        setFormType("job");
      }

    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  };

  const deactivateForm = () => {
    const mainScrollArea = document.querySelector(".main-area__scroll");
    mainScrollArea.classList.remove("freeze");
    setVisibile(false);
  }

  const submitForm = async () => {
    const section_id = document.getElementById("selection-stage")?.value;
    let campaign_type = 0;
    if (document.getElementById("type2")?.checked) {
      campaign_type = 1;
    }
    const campaign_title = document.getElementById("profile")?.value;
    if (!campaign_title) {
      document.getElementById("profile").focus();
      return;
    }

    const vacancies = parseInt(document.getElementById("openings")?.value);
    if (!vacancies) {
      document.getElementById("openings").focus();
      return;
    }

    const skills_required = [];

    const skill_list = document.getElementById("skills")?.parentElement.querySelectorAll(" .filters__choice__box--field");
    for (let skill of skill_list) {
      const skill_name = skill.innerText;
      if (skill_name) {
        skills_required.push(skill_name);
      }
    }

    const locations = [];
    const location_list = document.getElementById("cities")?.parentElement.querySelectorAll(" .filters__choice__box--field");
    for (let location of location_list) {
      const location_name = location.innerText;
      if (location_name) {
        locations.push(location_name.toLowerCase());
      }
    }

    let work_type = null;
    if (document.getElementById("work_type0")?.checked) {
      work_type = 0;
    }
    else if (document.getElementById("work_type1")?.checked) {
      work_type = 1;
    }
    else if (document.getElementById("work_type2")?.checked) {
      work_type = 2;
    }

    const min_experience = parseInt(document.getElementById("min-exp")?.value);
    const responsibilities = document.getElementById("responsibilities")?.value;
    if (!responsibilities) {
      document.getElementById("responsibilities").focus();
      return;
    }

    const preference = document.getElementById("preference")?.value;

    const perks = [];
    const perks_elements = document.querySelectorAll(".form-checks__container input");
    for (let perk of perks_elements) {
      if (perk.checked) {
        const perk_text = perk.parentElement.querySelector("label")?.innerText;
        perks.push(perk_text);
      }
    }

    const questions = [];
    const questions_elements = document.querySelectorAll(".form__question--text");
    for (let question of questions_elements) {
      questions.push(question.value);
    }

    // Internship Specific
    const intern_duration = parseInt(document.getElementById("intern-duration")?.value);
    if (formType==="intern" && !intern_duration) {
      document.getElementById("intern-duration").focus();
      return;
    }

    let stipend_type = 0;
    if (document.getElementById("stipend2")?.checked) {
      stipend_type = 1;
    }
    else if (document.getElementById("stipend3")?.checked) {
      stipend_type = 2;
    }

    const stipend_release = parseInt(document.getElementById("stipend-release")?.value);
    let stipend_amount = null;
    if (stipend_type === 0) {
      stipend_amount = { amt: parseInt(document.getElementById("intern-salary")?.value)}
      if (formType==="intern" && !stipend_amount.amt) {
        document.getElementById("intern-salary").focus();
        return;
      }
    }
    else if (stipend_type === 1) {
      stipend_amount = { 
        start: parseInt(document.getElementById("intern-salary-start")?.value),
        end: parseInt(document.getElementById("intern-salary-end")?.value)
      }

      if (formType==="intern" && !stipend_amount.start) {
        document.getElementById("intern-salary-start").focus();
        return;
      }

      if (formType==="intern" && !stipend_amount.end) {
        document.getElementById("intern-salary-end").focus();
        return;
      }
    }

    const ppo = document.getElementById("ppo0")?.checked || false;


    // Job Sepcific
    const part_time = document.getElementById("part-time0")?.checked || false;

    let ctc_type = 0;
    if (document.getElementById("salary2")?.checked) {
      ctc_type = 1;
    }

    let ctc = null;
    if (ctc_type === 0) {
      ctc = { amt: parseInt(document.getElementById("job-salary")?.value)}
      if (formType==="job" && !ctc.amt) {
        document.getElementById("job-salary").focus();
        return;
      }
    }
    else if (ctc_type === 1) {
      ctc = { 
        start: parseInt(document.getElementById("job-salary-start")?.value),
        end: parseInt(document.getElementById("job-salary-end")?.value)
      }

      if (formType==="job" && !ctc.start) {
        document.getElementById("job-salary-start").focus();
        return;
      }

      if (formType==="job" && !ctc.end) {
        document.getElementById("job-salary-end").focus();
        return;
      }
    }

    const probation = document.getElementById("probation-yes")?.checked;
    let probation_duration = null;
    if (probation) {
      probation_duration = parseInt(document.getElementById("probation-duration")?.value);
      if (formType==="job" && !probation_duration) {
        document.getElementById("probation-duration").focus();
        return;
      }
    }

    const api_data = {
      "section_id": section_id,
      "campaign_type": campaign_type,
      "campaign_title": campaign_title,
      "vacancies": vacancies,
      "skills_required": skills_required,
      "locations": locations,
      "work_type": work_type,
      "min_experience": min_experience,
      "responsibility": responsibilities,
      "preference": preference,
      "perks": perks,
      "questions": questions,

      // Internship
      "duration": intern_duration,
      "stipend_type": stipend_type,
      "stipend_release": stipend_release,
      "stipend_amount": stipend_amount,
      "ppo": ppo,

      // Job
      "part_time": part_time,
      "ctc_type": ctc_type,
      "ctc": ctc,
      "probation": probation,
      "probation_duration": probation_duration
    }

    try {
      if (mode === "create") {
        await api.post(`/company/campaign?session_id=${session_id}`, api_data);
      }
      else {
        await api.put(`/company/campaign?session_id=${session_id}&campaign_id=${campaign_id}`, api_data);
      }
      deactivateForm();
      populateCampaigns(true);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    if (mode && mode === "update") {
      fillCampaignData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="campaign-form">
      <div className="campaign-form__head">
        <button onClick={deactivateForm} className="campaign-form__head--back normal--button">
          <i className="uil uil-arrow-left" />
          <p>Back</p>
        </button>

        <h2 className="campaign-form__head--heading">
          {mode === "create" ? "Create New" : "Update"} Campaign
        </h2>
      </div>

      { mode !== "update" && <div className="campaign-form__container">
        <h4 className="campaign-form__container--title">Campaign Type</h4>
        <div className="form-horizontal padding-bottom border-line">
          <div className="form-horizontal">  

            <div className="form-group">
              <input
                type="radio"
                id="type2"
                name="form-loader"
                onChange={() => setFormType("job")}
                defaultChecked
              />
              <label htmlFor="type2">Job</label>
            </div>
            <div className="form-group">
              <input
                type="radio"
                id="type1"
                name="form-loader"
                onChange={() => setFormType("intern")}
                
              />
              <label htmlFor="type1">Internship</label>
            </div>
          </div>
        </div>
      </div>}

      {formType === "intern" ? (
        <InternshipForm mode={mode} submitFunc={submitForm} campaign_data={campaign_data} />
      ) : (
        <JobForm mode={mode} submitFunc={submitForm} campaign_data={campaign_data} />
      )}
    </div>
  );
}

export default CampaignForm;

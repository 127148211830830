import React, { useEffect, useRef, useState } from "react";
import "../../../css/Pages/job_seeker/profile/userDetails.css";
import { api } from "../../../backend";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';


const LOADING_IMG = process.env.REACT_APP_LOADING_IMG;

// choice selection box
function ChoiceSelection({choices=[], addToList}) {
  const selectionBox = useRef(0);

  useEffect(() => {
    const results = selectionBox.current?.querySelectorAll(".result");
    results.forEach((result) => {
      result.addEventListener("click", () => {
        const name = result.innerHTML;
        const searchBox = selectionBox.current?.parentElement.querySelector("input");
        addToList(name);
        searchBox.value = "";
        selectionBox.current?.classList.remove("visible");
      })
    })
  }, [addToList])

  return (
    <div ref={selectionBox} className={`selection__box ${choices.length?" visible":""}`}>
      {choices.map((choice) => <li key={uuidv4()} className="result">{choice}</li>)}
    </div>
  )
}


function UserDetails({ data, setPopup }) {
  const navigate = useNavigate();
  const session_id = localStorage.getItem('session_id');
  if (!session_id) {
    navigate("/login");
  }


  const [headlineLoad, setHeadlineLoad] = useState(false);

  const update_api_call = async (object) => {
    try {
      await api.put(`/profile/job-seeker/details?session_id=${session_id}`, object);
    }
    catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }
  }

  // Key Skills
  const [skills, setSkills] = useState([]);
  const [skillChoices, setSkillChoices] = useState([]);
  const [projects, setProjects] = useState(data?.projects);
  const [experience, setExperience] = useState(data?.experience);

  // Having work permit in other countries
  const [permitAreas, setPermitAreas] = useState([]);

  // Job Roles
  const [jobRoles, setJobRoles] = useState([]);

  // Education filled status
  const [education, setEducation] = useState({ x: false, xii: false, diploma: false, masters: false, phd: false});

  const addSkillSet = async (name="") => {
    if (name === "") {
      name = document.querySelector(".skills-div input")?.value;
      document.querySelector(".skills-div input").value = "";
    }

    if (skills.length >= 30) {
      alert("Only 30 skills are allowed")
      return;
    }

    for(let skill of skills) {
      if (skill === name) {
        return;
      }
    }

    const new_list = [...skills, name];
    localStorage.setItem("incomplete", JSON.stringify({value: false, time: Date.now()}));
    await update_api_call({ key_skills: new_list });
    setSkills(new_list);
  }

  const addJobRoles = async (name="") => {
    if (name === "") {
      name = document.querySelector(".roles-div input")?.value;
      document.querySelector(".roles-div input").value = "";
    }

    if (jobRoles.length >= 30) {
      alert("Only 30 Job roles are allowed")
      return;
    }

    for(let role of jobRoles) {
      if (role === name) {
        return;
      }
    }

    const new_list = [...jobRoles, name];
    await update_api_call({ job_roles: new_list });
    setJobRoles(new_list)
  }

  const addPermitArea = async (name="") => {
    if (name === "") {
      name = document.querySelector(".permit-div input")?.value;
      document.querySelector(".permit-div input").value = "";
    }

    if (permitAreas.length >= 5) {
      alert("Only 5 Countires are allowed");
      return;
    }

    for(let permit of permitAreas) {
      if (permit === name) {
        return;
      }
    }

    const new_list = [...permitAreas, name];
    await update_api_call({ work_permit_others: new_list });
    setPermitAreas(new_list)
  }


  const searchSkill = async (keyword) => {
    if (!keyword) {
      return;
    }

    try {
      const skills = await api.get(`/site-info/skills/search?keyword=${keyword}`)
      setSkillChoices(skills.data?.skills);
    }
    catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }
  }


  const removeOption = (e, setOption, type) => {
    const proceed = window.confirm("Are you sure you want to delete it?");
    if (!proceed) {
      return;
    }

    const optionName = e.currentTarget.parentElement?.querySelector("p")?.innerText;
    setOption((prv) => { 
      let filtered = prv.filter(option => option !== optionName);

      if (type === "skill") {
        update_api_call({ key_skills: filtered });
      }
      else if (type === "job_role") {
        update_api_call({ job_roles: filtered });
      }
      else if (type === "permit") {
        update_api_call({ work_permit_others: filtered });
      }
      return filtered;
    });
  }


  const removeProject = async (e, position) => {
    const proceed = window.confirm("Are you sure you want to delete it?");
    if (!proceed) {
      return;
    }

    try {
      let newPositioned = [];
      for (let i=0; i<projects.length; i++) {
        if (i === position) {
          continue;
        } else {
          newPositioned.push(projects[i]);
        }
      }

      setProjects(newPositioned);
      await api.delete(`/profile/job-seeker/project?position=${position}&session_id=${session_id}`);
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }
  }


  const removeExperience = async (e, position) => {
    const proceed = window.confirm("Are you sure you want to delete it?");
    if (!proceed) {
      return;
    }

    try {
      let newPositioned = [];
      for (let i=0; i<experience.length; i++) {
        if (i === position) {
          continue;
        } else {
          newPositioned.push(experience[i]);
        }
      }

      setExperience(newPositioned);
      await api.delete(`/profile/job-seeker/experience?position=${position}&session_id=${session_id}`);
      
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }
  }

  const format_exp_location = (location) => {
    if (location === 0) {
      return "Office";
    }
    else if (location === 1) {
      return "Remote";
    }
    else {
      return "Hybrid";
    }
  }

  const format_date = (date) => {
    if (date === "present") {
      return "present";
    }
    const month = parseInt(date.split("/")[1]);
    switch (month) {
      case 1:
        return "Jan " + date.split("/")[2];
      case 2:
        return "Feb " + date.split("/")[2];
      case 3:
        return "Mar " + date.split("/")[2];
      case 4:
        return "Apr " + date.split("/")[2];
      case 5:
        return "May " + date.split("/")[2];
      case 6:
        return "Jun " + date.split("/")[2];
      case 7:
        return "July " + date.split("/")[2];
      case 8:
        return "Aug " + date.split("/")[2];
      case 9:
        return "Sep " + date.split("/")[2];
      case 10:
        return "Oct " + date.split("/")[2];
      case 11:
        return "Nov " + date.split("/")[2];
      case 12:
        return "Dec " + date.split("/")[2];
      default:
        break;
    }
  }

  useEffect(() => {
    const resumeHeadline = document.querySelector(".resume-headline textarea");
    resumeHeadline?.addEventListener("input", (e) => {
      setHeadlineLoad(true);
      // removing all timeouts
      let id = window.setTimeout(function() {}, 0);
      while (id--) {
        window.clearTimeout(id);
      }

      const target = e.currentTarget;

      if (target?.value.length <= 600) {
        setTimeout(() => {
          setHeadlineLoad(false);
          update_api_call({ headline: target.value })
        }, 5000);
      }

      if (target?.value.length > 600) {
        target.value = target.value.slice(0, 600);
      }


      const stats = target?.parentElement?.querySelector(".headline-stats span");
      stats.innerText = resumeHeadline.value.length;
    });

    // Checking if clicked on choice selection box
    const body = document.querySelector('body');
    body.addEventListener("click", (e) => {
      const selectionBox = document.querySelector('.selection__box.visible');
      // if not then remove the focus from search box
      if (!e.target.classList?.contains("result") && selectionBox) {
        selectionBox?.classList?.remove("visible");
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSkills(data?.key_skills || []);
    setPermitAreas(data?.work_permit_others || []);
    setProjects(data?.projects || []);
    setExperience(data?.experience || []);
    setJobRoles(data?.job_roles || []);

    if (data?.education) {
      const edu_x_filled = data?.education[0].filled;
      const edu_xii_filled = data?.education[1].filled;
      const edu_diploma_filled = data?.education[2].filled;
      const edu_masters_filled = data?.education[3].filled;
      const edu_phd_filled = data?.education[4].filled;

      setEducation({
        x: edu_x_filled,
        xii: edu_xii_filled,
        diploma: edu_diploma_filled,
        masters: edu_masters_filled,
        phd: edu_phd_filled
      });
    }
  }, [data]);


  return (
    <div className="user-profile__details">
      <div className="resume-headline normal-border">
        <h4 className="normal-border--title">
          <span>Resume Headline</span>
          {headlineLoad?<img src={LOADING_IMG + "?tr=h-20,w-20"} alt="loading" />:<i className="uil uil-check" />}
        </h4>
        <p className="normal-border--info">
          It is the first thing recruiters notice in your profile. Tell them
          what makes you unique
        </p>
        <textarea
          className="resume-headline--text border"
          placeholder="Write Something..."
          defaultValue={data?.headline}
        />

        <div className="headline-stats"><span>{data?.headline?.length || 0}</span>/600</div>
      </div>

      <div className="skills-div normal-border selection-div">
        <h4 className="normal-border--title">Key Skills</h4>
        <p className="normal-border--info">
          Tell your recruiters what skills you have...
        </p>
        <div className="selection-div--showcase">
          {
            skills.map((skill) => (
              <div key={uuidv4()} className="option">
                <p>{skill}</p>
                <i className="uil uil-times" onClick={(e) => removeOption(e, setSkills, "skill")} />
              </div>
            ))
          }
        </div>

        {/* disable */}
        <div className={`selection-div--input ${skills.length === 0?"margin":""}`}>
          <input type="text" placeholder="Add new skill..." onInput={(e) => searchSkill(e.currentTarget.value)} />
          <button onClick={() => addSkillSet()}>Add</button>
          <ChoiceSelection choices={skillChoices} addToList={addSkillSet} />
        </div>

        <div className="selection-stats">{skills.length}/30</div>
      </div>

      <div className="user-experience normal-border">
        <div className="container-div">
          <h4 className="normal-border--title">Work Experience</h4>
          <button className="add-btn"
            onClick={() => setPopup({ state: true, type: "experience", action: "add"})}
          >
            Add New
          </button>
        </div>
        <p className="normal-border--info">
          Your recruiters notice where you have worked and what experience you
          have...
        </p>

        <div className="user-experience--showcase">
          {experience?.map((exp, i) => (
            <div key={uuidv4()} className="experience">
              <div className="container">
                {(exp?.work_type)?(
                  <p className="experience__type job">Job</p>
                ):(<p className="experience__type internship">Internship</p>)}
                <div className="experience__btns">
                  <button className="experience__btns--update"
                    onClick={() => setPopup({
                      state: true,
                      type: "experience",
                      action: "update",
                      id: i,
                      data: exp
                    })}
                  >
                    <i className="uil uil-edit-alt" />
                  </button>
                  <button className="experience__btns--delete"
                    onClick={(e) => removeExperience(e, i)}
                  >
                    <i className="uil uil-trash-alt" />
                  </button>
                </div>
              </div>

              <h2 className="experience__company-name">{exp?.comp_name}</h2>
              <h4 className="experience__position">{exp?.job_role}</h4>

              <div className="experience__duration">
                <p className="experience__duration--start">{format_date(exp?.duration.start)}</p>
                <div className="dash" />
                <p className="experience__duration--end">{format_date(exp?.duration.end)}</p>
              </div>

              <p className="experience__description">{exp?.description}</p>

              <p className="experience__location">
                Location: <span>{format_exp_location(exp?.working)}</span>
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="education normal-border">
        <h4 className="normal-border--title">Education</h4>

        <div className="education--showcase">
          <button className={`showcase-btn ${(education?.phd)?"green":""}`}
              onClick={() => setPopup({
                state: true,
                type: "education",
                action: (education?.phd)?"update":"add",
                edu_type: "phd",
                data: data?.education
              })}
          >{ (education?.phd)?"Update":"Add" } doctorate/PhD</button>

          <button className={`showcase-btn ${(education?.masters)?"green":""}`}
            onClick={() => setPopup({
              state: true,
              type: "education",
              action: (education?.masters)?"update":"add",
              edu_type: "masters",
              data: data?.education
            })}
          >{ (education?.masters)?"Update":"Add" } masters/post-graduation</button>

          <button className={`showcase-btn ${(education?.diploma)?"green":""}`}
            onClick={() => setPopup({
              state: true,
              type: "education",
              action: (education?.diploma)?"update":"add",
              edu_type: "deploma",
              data: data?.education
            })}
          >{ (education?.diploma)?"Update":"Add" } Graduation/Diploma</button>

          <button className={`showcase-btn ${(education?.xii)?"green":""}`}
            onClick={() => setPopup({
              state: true,
              type: "education",
              action: (education?.xii)?"update":"add",
              edu_type: "xii",
              data: data?.education
            })}
          >{ (education?.xii)?"Update":"Add" } class XII</button>

          <button className={`showcase-btn ${(education?.x)?"green":""}`}
            onClick={() => setPopup({
              state: true,
              type: "education",
              action: (education?.x)?"update":"add",
              edu_type: "x",
              data: data?.education
            })}
          >{ (education?.x)?"Update":"Add" } class X</button>
        </div>
      </div>

      <div className="projects normal-border">
        <div className="container-div">
          <h4 className="normal-border--title">Projects</h4>
          <button className="add-btn"
            onClick={() => setPopup({ state: true, type: "project", action: "add"})}
          >Add New</button>
        </div>

        <p className="normal-border--info">
          Recruiters pays more attention in projects which you recently build to
          showcase you skill sets
        </p>

        <div className="projects--showcase">
          {(projects?.map((project, i) => (
            <div className="project" key={uuidv4()}>
              <div className="container">
                <h4 className="project--name">{project?.name}</h4>
                <div className="project__btns">
                  <button
                    className="project__btns--update"
                    onClick={() => setPopup({
                      state: true,
                      type: "project",
                      action: "update",
                      id: i,
                      data: project
                    })}
                  >
                    <i className="uil uil-edit-alt" />
                  </button>
                  <button
                    className="project__btns--delete"
                    onClick={(e) => removeProject(e, i)}
                  >
                    <i className="uil uil-trash-alt" />
                  </button>
                </div>
              </div>

              {project?.description && <p className="project--description">{project?.description}</p>}
              <div className="project--source__btns">
                {project?.source_code && (
                  <a href={project?.source_code} target="__blank" className="project__btns--source">
                    Source <i className="uil uil-github" />
                  </a>
                )}

                {project?.live_link && (
                  <a href={project?.live_link} target="__blank" className="project__btns--live">
                    Live <i className="uil uil-link" />
                  </a>
                )}
              </div>
            </div>
          )))}
        </div>
      </div>

      <div className="roles-div normal-border selection-div">
        <h4 className="normal-border--title">Job Roles</h4>
        <p className="normal-border--info">
          Tell your recruiters what job roles you have worked on...
        </p>
        <div className="selection-div--showcase">
          {
            jobRoles.map((role) => (
              <div key={uuidv4()} className="option">
                <p>{role}</p>
                <i className="uil uil-times" onClick={(e) => removeOption(e, setJobRoles, "job_role")} />
              </div>
            ))
          }
        </div>

        {/* disable */}
        <div className={`selection-div--input ${skills.length === 0?"margin":""}`}>
          <input type="text" placeholder="Add job role..." />
          <button onClick={() => addJobRoles()}>Add</button>
        </div>

        <div className="selection-stats">{jobRoles.length}/30</div>
      </div>

      <div className="permit-div normal-border selection-div">
        <h4 className="normal-border--title">Work Permit</h4>
        <p className="normal-border--info">
          Mention all the countries you have work permit for...
        </p>
        <div className="selection-div--showcase">
          {
            permitAreas.map((permit) => (
              <div key={uuidv4()} className="option">
                <p>{permit}</p>
                <i className="uil uil-times" onClick={(e) => removeOption(e, setPermitAreas, "permit")} />
              </div>
            ))
          }
        </div>

        {/* disable */}
        <div className={`selection-div--input ${permitAreas.length === 0?"margin":""}`}>
          <input type="text" placeholder="Enter country name..." />
          <button onClick={() => addPermitArea()}>Add</button>
        </div>

        <div className="selection-stats">{permitAreas.length}/5</div>
      </div>
    </div>
  );
}

export default UserDetails;

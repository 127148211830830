import { Routes, Route, useNavigate } from "react-router-dom";

//google anayltics
import ReactGA from "react-ga4";

// Default site pages
import Home from "./Pages/default_pages/home/Main";
import ConsultantsInfo from "./Pages/default_pages/consultant-info/Main";
import ContactUs from "./Pages/default_pages/contact-us/Main";
import Privacy from "./Pages/default_pages/privacy/Main";
import TermsCondition from "./Pages/default_pages/terms-condition/Main";
import Searching from "./Pages/default_pages/searching/Main";
import Campaign from "./Pages/default_pages/campaign/Main";
import Login from "./Pages/default_pages/login/Main";
import ForgotPass from "./Pages/default_pages/forgot-password/Main";
import Register from "./Pages/default_pages/register/Main";

// Consultant pages
import Mainarea from "./Pages/company_pages/Mainarea";

// Jobseeker pages
import Profile from "./Pages/job_seeker/profile/Main";
import Applied from "./Pages/job_seeker/applied/Main";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Popup from "./Popup";
const GATAG = process.env.REACT_APP_GOOGLEG4TAG;


function NotFound() {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>
}
 //google anayltics
 //const ga4react = new ReactGA("G-C0BFP46PVH");

ReactGA.initialize(GATAG);
function App() {
  const [cookieAccepted, setCookieAccepted] = useState(localStorage.getItem("cookie-accepted") || false);

  return (
    <>
      {!cookieAccepted && <div className="policy-cookie px-1.5 py-4 leading-normal">
        We use cookies to improve your experience. By continuing to browse the site, you agree to our 
        <a href="/privacy" className="text-nowrap" target="_blank">Privacy Policy</a>
        <button className="text-black mt-2" onClick={() => {
          localStorage.setItem("cookie-accepted", true);
          setCookieAccepted(true);
        }}>Accept</button>
      </div>
      }
      <div className="App">
        <Routes>
          {/* default site pages */}
          <Route path="/" element={
            <>
              <Popup />
              <Helmet>
                <title>JustFreshJobs - Top 5 Job Portals</title>
                <meta property="og:description" content="JustFreshJobs platform is designed to simplify your job search and connect you with top employers across various industries. With our advanced matching algorithm, you'll get personalized job recommendations based on your skills and experience, making it easier to find the perfect fit.
Find jobs and internships with JustFreshJobs" />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals,find internships" />
                <meta charset="utf-8" />
                
                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - Top 5 Job Portals" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <Home />
            </>
          } />

          <Route path="/consultants" element={
            <>
              <Helmet>
                <title>JustFreshJobs - Hire Talent</title>
                <meta property="og:description" content="JustFreshJobs helps to job seekers and employers! Discover your next opportunity with JustFreshJobs. Our platform is designed to connect talented professionals with top employers across various industries. Whether you're looking for a job or hiring for your organization, we've got you covered" />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals,find internships" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - Hire Talent" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              
              <ConsultantsInfo />
            </>
          } />

          <Route path="/contact-us" element={
            <>
              <Helmet>
                <title>JustFreshJobs - TOP 10 JobPortals</title>
                <meta property="og:description"  content="Our platform is designed to simplify your job search and connect you with top employers across various industries. With our advanced matching algorithm, you'll get personalized job recommendations based on your skills and experience, making it easier to find the perfect fit." />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals,find internships" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs-TOP 10 JobPortals-Contact ME" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <ContactUs />
            </>
          } />

          <Route path="/privacy" element={
            <>
              <Helmet>
                <title>JustFreshJobs - Privacy Policy</title>
                <meta property="og:description" content="JustFreshJobs privacy policy page" />
                <meta name="keywords" content="justfreshjobs privacy policy, privacy page, privacy policy, just fresh jobs privacy" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - Privacy Policy" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <Privacy />
            </>
          } />

          <Route path="/terms-conditions" element={
            <>
              <Helmet>
                <title>JustFreshJobs - Terms & Conditions</title>
                <meta property="og:description" content="JustFreshJobs termns and conditions pages" />
                <meta name="keywords" content="justfreshjobs terms and conditions, terms and condition page, terms and conditions, just fresh jobs terms" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - Terms & Conditions" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <TermsCondition />
            </>
          } />

          <Route path="/search" element={
            <>
              <Popup />
              <Helmet>
                <title>JustFreshJobs - TOP 10 Jobs & Internships</title>
                <meta property="og:description" content="JustFreshJobs platform is designed to simplify your job search and connect you with top employers across various industries. With our advanced matching algorithm, you'll get personalized job recommendations based on your skills and experience, making it easier to find the perfect fit.
Find jobs and internships with JustFreshJobs" />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals,find internships" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - TOP 10 Jobs & Internships" />

                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <Searching />
            </>
          } />

          <Route path="/search/job" element={
            <>
              <Popup />
              <Helmet>
                <title>JustFreshJobs - TOP 10 WORK FROM Jobs</title>
                <meta property="og:description" content="Discover your dream career with JustFreshJobs - your ultimate destination for finding the perfect job opportunity. Browse thousands of job listings, from top companies to startups, and apply with ease. Let JustFreshJobs streamline your job search, connecting you with employers seeking your unique skills and experience. Start your journey to professional success today!" />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals,find internships" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - TOP 10 WORK FROM Jobs" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <Searching key={1} work_type={"job"} />
            </>
          } />

          <Route path="/search/intern" element={
            <>
              <Popup />
              <Helmet>
                <title>JustFreshJobs - Search Internships</title>
                <meta property="og:description" content="Unlock your potential with TOP Internships - your gateway to a world of valuable opportunities with JustFreshJobs.com. Discover the best internships from leading companies and organizations to kickstart your career journey with JustFreshJobs.com. Gain hands-on experience, mentorship, and valuable skills in your field of interest. TOP Internships connects ambitious students and young professionals with top-notch internship placements. Start building a successful future today!" />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - Search Internships" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <Searching key={2} work_type={"intern"} />
            </>
          } />

          <Route path="/campaign/:campaign_title/:action" element={<Campaign />} />

          <Route path="/login" element={
            <>
              <Helmet>
                <title>JustFreshJobs - Login</title>
                <meta property="og:description" content="Securely access your personalized job search experience at JustFreshJobs.com's JobPortal Login. Find the perfect career opportunity tailored to your skills and aspirations. Connect with top employers, apply for jobs, and manage your applications effortlessly. JobPortal Login is your key to unlocking a world of professional possibilities. Sign in now and take the next step towards your dream job!" />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - Login" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta name= "url" property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <Login />
            </>
          } />

          <Route path="/forgot-pass" element={
            <>
              <Helmet>
                <title>JustFreshJobs - Forgot Password</title>
                <meta property="og:description" content="Forgot password page of JustFreshJobs" />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals" />
                <meta charset="utf-8" />
                
              </Helmet>
              <ForgotPass />
            </>
          } />

          <Route path="/register" element={
            <>
              <Helmet>
                <title>JustFreshJobs - Register</title>
                <meta property="og:description" content="Join the vibrant community of job seekers on JustFreshJobs.com's JobPortal Signup. Create your personalized profile and embark on a seamless journey to find the ideal job opportunity. Gain access to thousands of job listings from diverse industries and connect with top employers. Let JobPortal Signup empower your career search with simplicity and efficiency. Start your adventure towards a rewarding future today!" />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - Register" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <Register />
            </>
          } />

          <Route path="/register/company" element={
            <>
              <Helmet>
                <title>JustFreshJobs - Register Company</title>
                <meta property="og:description" content="Discover your next career move with JustFreshJobs, your premier job portal. Explore a wide range of opportunities across industries. Register or log in now to unlock your job-seeking potential and connect with top employers" />
                <meta name="keywords" content="JobPortal,JustFreshJobs,Job search,Employment opportunities,Career opportunities,Job listings,Job vacancies,Job openings,Job seekers,Job postings,Job application,Job matching,Job alerts,Resume builder,Professional networking,Employment platform,Job search engine,Hiring,Recruitment,Employment,Careers,Job seekers website,Job database,Find jobs online,Apply for jobs,Job hunting,TOP 10Jobs, TOP 5 Jobs, IT JOBS,TOP internship, TOP 10 Internship, Latest Jobs, TOP 5 JobPortals, TOP 10 JobPortals" />
                <meta charset="utf-8" />
                 

                <meta property="og:type" content="website" />
                <meta property="og:title" content="JustFreshJobs - Register Company" />
                
                <meta property="og:image" content="https://www.justfreshjobs.com/assets/site_logo.png" />
                <meta property="og:url" content="https://www.justfreshjobs.com" />
              </Helmet>
              <Register key={2} jobSeeker={false} />
            </>
          } />


          {/* consultant pages */}
          <Route path="/my-account" element={
            <>
              <Helmet>
                <title>Dashboard - Home</title>
                
              </Helmet>
              <Mainarea />
            </>
          } />

          <Route path="/my-account/profile" element={
            <>
              <Helmet>
                <title>Dashboard - Profile</title>
                
              </Helmet>
              <Mainarea show="profile" />
            </>
          } />

          <Route path="/my-account/campaigns" element={
            <>
              <Helmet>
                <title>Dashboard - Campaigns</title>
                
              </Helmet>
              <Mainarea show="campaigns" />
            </>
          } />

          {/* <Route path="/my-account/selection-stages" element={
            <>
              <Popup />
              <Helmet>
                <title>Dashboard - Selection Stages</title>
                
              </Helmet>
              <Mainarea show="selection-stages" />
            </>
          } /> */}

          <Route path="/my-account/market-place" element={
            <>
              <Helmet>
                <title>Dashboard - Market Place</title>
                <meta content="JustFreshJobs dashboard market place" />
              </Helmet>
              <Mainarea show="market-place" />
            </>
          } />

          <Route path="/my-account/helpers" element={
            <>
              <Helmet>
                <title>Dashboard - Helpers</title>
                <meta content="JustFreshJobs dashboard helpers" />
              </Helmet>
              <Mainarea show="helpers" />
            </>
          } />

          <Route path="/my-account/application/:application_id" element={
            <>
              <Helmet>
                <title>Dashboard - Campaign Application</title>
                <meta content="JustFreshJobs dashboard application page" />
              </Helmet>
              <Mainarea show="application" />
            </>
          } />

          <Route path="/my-account/candidate/:user_id" element={<Mainarea show="candidate" />} />


          {/* Jobseeker Area */}
          <Route path="/user/profile" element={
            <>
              <Helmet>
                <title>Profile</title>
                <meta content="Jobseeker profile" />
              </Helmet>
              <Profile />
            </>
          } />

          <Route path="/applied" element={
            <>
              <Popup />
              <Helmet>
                <title>Applied Campaigns</title>
                <meta content="Jobseeker all applied campaigns" />
              </Helmet>
              <Applied />
            </>
          } />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
}

export default App;

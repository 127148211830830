import React, { useEffect, useRef, useState } from "react";
import { api } from "../../../backend";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";


const locations = JSON.parse(process.env.REACT_APP_LOCATION);

function getLocation(query) {
  const filtered = [];

  if (!query) {
    return filtered;
  }

  for(let location of locations) {
    if (location.toLowerCase().indexOf(query.toLowerCase()) === 0) {
      filtered.push(location);

      if (filtered.length === 5) {
        break;
      }
    }
  }

  return filtered;
}


export function TextInput({ label, placeholder, id, default_value, size=100 }) {
  const formDiv = useRef(null);

  useEffect(() => {
    const inputField = formDiv.current.querySelector("input");
    const statusField = formDiv.current.querySelector(".status-text");

    inputField.addEventListener("input", () => {
      if (inputField.value.length > size) {
        inputField.value = inputField.value.slice(0, size);
      }
      statusField.querySelector("span").innerText = inputField.value.length;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-div" ref={formDiv}>
      <label htmlFor={id}>{label}</label>
      <input type="text" id={id} placeholder={placeholder} defaultValue={default_value || ""} />
      <div className="error">
        <i className="uil uil-exclamation-octagon"></i>
        <p className="error--text">This field is required</p>
      </div>
      <p className="status-text"><span>{default_value?.length || 0}</span> / {size}</p>
    </div>
  );
}

export function NumberInput({ label, placeholder, id, default_value }) {
  return (
    <div className="form-div">
      <label htmlFor={id}>{label}</label>
      <input type="number" id={id} placeholder={placeholder} defaultValue={default_value} />
      <div className="error">
        <i className="uil uil-exclamation-octagon"></i>
        <p className="error--text">This field is required</p>
      </div>
    </div>
  );
}

export function RadioList({ radioInfo, id, name, label, default_value }) {

  return (
    <div id={id} className="margin-bottom">
      <h4 className="form-input-heading margin">{label}</h4>
      <div className="form-horizontal border-line">
        {radioInfo.map((info, i) => {
          if ((default_value || default_value===0) && (i===default_value)) {
            return (
              <div className="form-group" key={uuidv4()}>
                <input type="radio" id={`${id}${i}`} name={name} defaultChecked />
                <label htmlFor={`${id}${i}`}>{info}</label>
              </div>
            )
          } else {
            return (
              <div className="form-group" key={uuidv4()}>
                <input type="radio" id={`${id}${i}`} name={name} />
                <label htmlFor={`${id}${i}`}>{info}</label>
              </div>
            )
          }
        })}
      </div>
      <div className="error">
        <i className="uil uil-exclamation-octagon"></i>
        <p className="error--text">This field is required</p>
      </div>
    </div>
  );
}

export function SelectInput({ optional = false, label, placeholder, id, default_value, size=5 }) {
  const navigate = useNavigate();
  const [options, setOptions] = useState(default_value || []);
  const [choices, setChoices] = useState([]);
  const searchBox = useRef(null);
  const choiceBox = useRef(null);
  const selectionBox = useRef(null);
  const formDiv = useRef(null);

  useEffect(() => {
    setOptions(default_value);
    if (default_value?.length > 0) {
      searchBox.current?.classList?.remove("initial")
      searchBox.current.placeholder = "";
    }
    else {
      searchBox.current?.classList?.add("initial")
      searchBox.current.placeholder = placeholder;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_value]);


  // Adding location to list for filtering
  const addOption = (result) => {
    // if result is disabled, do nothing
    if (result.classList.contains("disable")) return
    const name = result.innerText;
    searchBox.current.placeholder = "";
    searchBox.current.classList.remove("initial")
    searchBox.current.value = ""
    selectionBox.current.classList.remove("visible");

    for(let option of options) {
      if (option === name) {
        return;
      }
    }

    if (size === options.length) {
      return;
    }

    if (options.length > (id==="skills"?10:5)) {
      alert(`Only ${id==="skills"?10:5} ${id==="skills"?"Skills":"Locations"} are allowed`);
      return;
    }

    setOptions([...options, name])
  }

  const removeChoice = (e) => {
    const choice = e.currentTarget;
    const choiceFields = choice.parentElement;
    const filtersChoiceBox = choiceFields.parentElement;

    const allFields = filtersChoiceBox.querySelectorAll(".filters__choice__box--field");
    const input = filtersChoiceBox.querySelector('.filters__choice__box--search');

    // meaning - if only one choice is present (it is going to be removed)
    if (allFields.length === 1) {
      input.placeholder = placeholder;
      input.classList.add('initial');
    }else {
      // if more than one choice is present
      input.placeholder = "";
      input.classList.remove('initial');
    }

    setOptions(options.filter(op => op !== choiceFields.innerText));
  }

  useEffect(() => {
    choiceBox.current.addEventListener("click", (e) => {
      const clickedTag = e.target.tagName.toLowerCase();
      if (clickedTag !== 'i') {
        searchBox.current.focus();
        choiceBox.current.classList.add("active");
      }
    });

    // Checking if clicked on choice selection box
    const body = document.querySelector('body');
    body.addEventListener("click", (e) => {
      if (!e.target.classList.contains("result")) {
        selectionBox.current?.classList.remove("visible");
        choiceBox.current?.classList.remove("active")
        if (searchBox.current) {
          searchBox.current.value = "";
        }
      }
    });

    searchBox.current.addEventListener("input", () => {
      const highestTimeoutId = setTimeout(() => {}, 2000);
      for (var i = 0 ; i < highestTimeoutId ; i++) {
          clearTimeout(i); 
      }

      setTimeout(async () => {
        const keyword = searchBox.current.value;
        if (keyword) {
          try {
            let choices = [];
            if (id === "skills") {
              choices = (await api.get(`/site-info/skills/search?keyword=${keyword}`)).data.skills;
            } else {
              choices = getLocation(keyword);
            }
            setChoices(choices);
          }
          catch (err) {
            if (err.response.status === 401 || err.response.status === 403) {
              localStorage.removeItem('session_id');
              localStorage.removeItem('session_type');
              navigate("/login");
            } else {
              alert(err.response.data.msg);
            }
          }
        }
      }, 1000);

      selectionBox.current.classList.add('visible');
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="form-div" ref={formDiv}>
      <label htmlFor={id}>
        {label} {optional ? <span>(Optional)</span> : ""}
      </label>
      <ul className="filters__choice__box" ref={choiceBox}>
        {options?.map((option) => (
          <li key={uuidv4()} className="filters__choice__box--field">
            {option}  <i onClick={removeChoice} className="uil uil-multiply"></i>
          </li>
        ))}
        <input
          type="text"
          className={`filters__choice__box--search ${id==="skills"?"search-skill":"search-location"}`}
          ref={searchBox}
          placeholder={placeholder}
          id={id}
        />
        <div ref={selectionBox} className="filters__choice__box__selection">
          {choices?.map((choice) => (
            <li key={uuidv4()} className="result" onClick={(e) => addOption(e.currentTarget)}>{choice}</li>
          ))}
        </div>
      </ul>
      <div className="error">
        <i className="uil uil-exclamation-octagon"></i>
        <p className="error--text">This field is required</p>
      </div>
      <p className="status-text"><span>{options?.length || 0}</span> / {size}</p>
    </div>
  );
}


export function TextAreaInput({ label, preloaded_msg=[], id, placeholder="", optional=false, default_value, size=1500 }) {
  const formDiv = useRef(null);

  useEffect(() => {
    const textarea = document.getElementById(id);
    textarea.innerHTML = default_value || preloaded_msg.join("\n");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_value]);

  useEffect(() => {
    const inputField = formDiv.current.querySelector("textarea");
    const statusField = formDiv.current.querySelector(".status-text");

    inputField.addEventListener("input", () => {
      if (inputField.value.length > size) {
        inputField.value = inputField.value.slice(0, size);
      }
      statusField.querySelector("span").innerText = inputField.value.length;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="form-div" ref={formDiv}>
      <label htmlFor={id}>
        {label} {optional ? <span>(Optional)</span> : ""}
      </label>
      <textarea type="text" id={id} placeholder={placeholder} />
      <div className="error">
        <i className="uil uil-exclamation-octagon"></i>
        <p className="error--text">This field is required</p>
      </div>
      <p className="status-text"><span>{(default_value || preloaded_msg.join("\n"))?.length || 0}</span> / {size}</p>
    </div>
  );
}


export function FixedSalary({ label="", id, placeholder, showType=true, default_value, salary_release }) {
  return (
    <>
    <div className={"form-div " + (label?"margin":"")}>
      <label>{label}</label>
      <div className="form-horizontal form-price-box margin-none">
        <select name="currency-type" id="currency-type">
          <option value="rupee" selected>₹</option>
          {/* <option value="dollar">$</option> */}
        </select>

        <input type="number"  id={id} placeholder={placeholder} className={id} defaultValue={default_value} />

        { showType && <select name="stipend-release" id="stipend-release">
          {salary_release===0 ?
            (<option value="0" selected>Weekly</option>):
            (<option value="0">Weekly</option>)
          }
          {salary_release===1 ?
            (<option value="1" selected>Monthly</option>):
            (<option value="1">Monthly</option>)
          }
          {salary_release===2 ?
            (<option value="2" selected>Lump-sum</option>):
            (<option value="2">Lump-sum</option>)
          }
          </select>
        }
      </div>
    </div>
    </>
  )
}


export function RangeSalary({ placeholder1, placeholder2, id, duration=true, label="", default_value, salary_release}) {
  return (
    <div className={"form-div " + (label?"margin":"")}>
      <label>{label}</label>
      <div className="form-horizontal range-div">

      <div className="form-price-box starting-box">
        <select name="currency-type" id="currency-type">
          <option value="rupee" selected>₹</option>
          {/* <option value="dollar">$</option> */}
        </select>

        <input type="number" id={id + "-start"} placeholder={placeholder1} className={id} defaultValue={default_value?.start} />
      </div>

      <div className="form-price-box msg-box">
        <h4>to</h4>
      </div>

      <div className="form-price-box ending-box">

        <input type="number" id={id + "-end"} placeholder={placeholder2} defaultValue={default_value?.end} />

        {duration && (
          <select name="stipend-release" id="stipend-release">
          {salary_release===0 ?
            (<option value="0" selected>Weekly</option>):
            (<option value="0">Weekly</option>)
          }
          {salary_release===1 ?
            (<option value="1" selected>Monthly</option>):
            (<option value="1">Monthly</option>)
          }
          {salary_release===2 ?
            (<option value="2" selected>Lump-sum</option>):
            (<option value="2">Lump-sum</option>)
          }
          </select>
        )}
      </div>
    </div>
  </div>
  )
}


export function CheckList({ optional=false, id, label, checks, default_checks }) {

  const check_value = (value) => {
    for (let i of (default_checks || [])) {
      if (i === value) {
        return true;
      }
    }

    return false;
  }
  return (
    <div className="form-div">
      <label htmlFor={id}>
        {label} {optional ? <span>(Optional)</span> : ""}
      </label>

      <div className="form-checks">
        { checks?.map( (container, i) => (
            <div key={uuidv4()} className="form-checks__container">
              {container?.map((check, x) => (
                <div className="checkbox" key={uuidv4()}>
                  {check_value(check)?
                    (<input type="checkbox" id={`check--${i}-${x}`} defaultChecked />):
                    (<input type="checkbox" id={`check--${i}-${x}`} />)
                  }
                  
                  <label htmlFor={`check--${i}-${x}`}>{check}</label>
                </div>
              ))}
            </div>
        ))}
      </div>
    </div>
  );
}


export function SelectionStage({ label, default_value }) {
  const navigate = useNavigate();
  const session_id = localStorage.getItem("session_id");
  const [stages, setStages] = useState([]);

  const popuplateStages = async () => {
    try {
      const api_call = await api.get(`/company/section/all?session_id=${session_id}`);
      const allSections = api_call.data.sections;
      const stage_data = [];

      for (let section of allSections) {
        if (section._id === default_value) {
          stage_data.push({ _id: section._id, title: section.title, selected: true });
        } else {
          stage_data.push({ _id: section._id, title: section.title, selected: false });
        }
      }

      setStages(stage_data);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  };

  const selectionConfirm = (e) => {
    const isOptionSelected = e.currentTarget.querySelector(".default-selected");
    if (isOptionSelected) {
      const confirm = window.confirm("Changing section will remove all the candidates saved in stages. Are you sure?");
      if (confirm) {
        return;
      }

      const allOptions = e.currentTarget.querySelectorAll("option");
      for (let option of allOptions) {
        if (option.value === default_value) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      }
    }
  }

  useEffect(() => {
    popuplateStages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_value]);

  return (
    <div className="form-div">
      
      {/* <label htmlFor="selection-stage">{label} <a href="/my-account/selection-stages" target="_blank" rel="no-reference">View Stages</a></label> */}
      <label htmlFor="selection-stage">{label} View Stages</label>
      <select className="form-selection-stage" id="selection-stage" onChange={selectionConfirm}>
        {
          stages?.map((section) => {
            if (section?.selected) {
              return <option value={section?._id} key={uuidv4()} className="default-selected" selected>{section?.title}</option>
            } else {
              return <option value={section?._id} key={uuidv4()}>{section?.title}</option>
            }
          })
        }
      </select>
    </div>
  )
}

export function CoverLetter({ campaign_data, que_size=300 }) {
  const [allQuestions, setAllQuestions] = useState([]);

  function Question({ num, value=""}) {
    const formMain = useRef(null);
    
    const remove = () => {
      const form_questions = document.querySelectorAll(".form__question--text");
      let newQuesList = [];
      for (let i=0; i<allQuestions.length-1; i++) {
        if (i === num-1) {
          continue;
        } else {
          newQuesList.push(form_questions[i].value);
        }
      }

      setAllQuestions([allQuestions[0], ...newQuesList]);
    }

    useEffect(() => {
      const statusText = formMain.current.querySelector(".status-text span");
      const textArea = formMain.current.querySelector("textarea");
      textArea.addEventListener("input", () => {
        if (textArea.value.length > que_size) {
          textArea.value = textArea.value.slice(0, que_size);
        }

        statusText.innerText = textArea.value.length;
      });
    }, []);

    return (
      <div className="form__question" ref={formMain}>
        <div className="form__question--head">
          <p className="form-highlight">Assessment question {num}</p>
          <button className="form__question--close" onClick={remove}>
            <i className="uil uil-multiply"> </i>
            <p>Remove</p>
          </button>
        </div>
        <textarea className="form__question--text" placeholder="Type your question here" defaultValue={value}></textarea>
        <p className="status-text"><span>{value?.length || 0}</span> / {que_size}</p>
      </div>
    )
  }

  useEffect(() => {
    setAllQuestions(campaign_data?.questions || []);
  }, [campaign_data]);

  const addQuestion = () => {
    const form_questions = document.querySelectorAll(".form__question--text");
    const current_values = [];

    for (let i of form_questions) {
      current_values.push(i.value);
    }

    setAllQuestions([ allQuestions[0], ...current_values, ""])
  }


  return (
    <div className="campaign-form__container">
      <h4 className="campaign-form__container--title">
        Cover letter & Assessment question
      </h4>
      <div className="form-section padding-bottom">
        <p className="form-text light">
          Cover letter & availability question will be asked to every applicant
          by default. If you wish, you may ask two more customized questions as
          an assessment.
        </p>
        <p className="form-highlight">Cover letter</p>
        <p className="form-text">Why should you be hired for this role?</p>
        <p className="form-highlight">Availability</p>
        <p className="form-text">
          Are you available for 1 month, starting immediately, for a full-time
          work from home internship? If not, what is the time period you are
          available for and the earliest date you can start this internship on?
        </p>

        <div className="form__question__container">
          {allQuestions.slice(allQuestions.length===0?0:1, allQuestions.length).map((que, i) => (
            <Question key={uuidv4()} num={i+1} value={que} />
          ))}
        </div>

        { allQuestions.length <= 3 && (
          <button className="form__question--add" onClick={addQuestion}>
            <i className="uil uil-plus" /> Add assessment question
          </button>
        )}
      </div>
    </div>
  );
}
import React, { useEffect, useRef } from "react";
import "../../../css/Pages/company_pages/stages/popup.css";

function Popup({ cancelFnc=() => {}, type, action, value, returnFun=() => {} }) {
  const input = useRef(null);

  useEffect(() => {
    document.querySelector(".popup-box .text-fields input").focus();
    input.current.placeholder = `Enter ${type} name`;
  }, [type, action]);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (e.target.classList.contains("popup-blackscreen")) {
        cancelFnc();
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="popup-blackscreen">
      <div className="popup-box rename">
        <div className="text-fields">
          <input type="text" id="popup-value" ref={input} defaultValue={value || ""} />
        </div>

        <p className="error">Next Field Empty</p>

        <div className="btns-container">
          <button className="btns--create" onClick={() => returnFun(input.current?.value)}>{action}</button>
          <button className="btns--cancel" onClick={cancelFnc}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default Popup;

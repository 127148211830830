import React, { useEffect } from "react";
import "../../css/Pages/company_pages/sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { api } from "../../backend";

const menu_options = [
  { name: "Overview", icon: "uil uil-dashboard", link: "/my-account", helper: false},
  { name: "Campaigns", icon: "uil uil-megaphone", link: "/my-account/campaigns", helper: true},
  // { name: "Selection Stages", icon: "uil uil-file-graph", link: "/my-account/selection-stages", helper: true},
  { name: "Market Place", icon: "uil uil-database", link: "/my-account/market-place", helper: true},
  { name: "Helpers", icon: "uil uil-users-alt", link: "/my-account/helpers", helper: false},
  { name: "Profile", icon: "uil uil-pen", link: "/my-account/profile", helper: true},
]

const support_options = [
  { name: "Subscription", link: "/subscription", icon: "uil uil-star", helper: false},
  { name: "Help", link: "/contact-us", icon: "uil uil-question-circle", helper: true},
]

function Sidebar( { pageLink } ) {
  const navigate = useNavigate();

  const minimizeSidebar = (localStorage.getItem("sidebar_minimize") === "true");
  const isHelper = localStorage.getItem("session_type") === "helper";

  useEffect(() => {
    const blackScreen = document.querySelector(".black-screen");
    blackScreen.addEventListener("click", () => {
      document.querySelector(".sidebar").classList.remove("active");
      blackScreen.classList.remove("active");
    });

    const menuLinks = document.querySelectorAll(".sidebar__link-group--link.menu");
    menuLinks.forEach((link) => link.addEventListener("click", () => {
      const alreadyActive = document.querySelector(".sidebar__link-group--link.active");
      alreadyActive?.classList.remove("active");
      link.classList.add("active");
      blackScreen.click();

      // remove freeze if any
      document.querySelector(".main-area__scroll")?.classList.remove("freeze");
    }));

    const supportLinks = document.querySelectorAll(".sidebar__link-group--link.support");
    supportLinks?.forEach((link) => link.addEventListener("click", () => {
      blackScreen.click();
    }));

    const darkThemeSwitch = document.querySelector(".dark-mode__switcher");
    darkThemeSwitch?.addEventListener("click", () => {
      darkThemeSwitch.classList.toggle("active");
    });
  }, []);

  const logoutApi = async () => {
    const confirm = window.confirm("Are you sure you want to log out?");
    if (!confirm) return;

    try {
      await api.delete(`/logout?session_id=${localStorage.getItem("session_id")}`);
      localStorage.removeItem("session_id");
      localStorage.removeItem("session_type");
      navigate("/");
    } catch (err) {
      localStorage.removeItem('session_id');
      localStorage.removeItem('session_type');
      if (err.response.status === 401 || err.response.status === 403) {
        navigate("/login");
      } else {
        navigate("/");
      }
    }
  }

  return (
    <>
    <div className={`sidebar ${minimizeSidebar? "minimize": ""}`}>
      <div className="sidebar__link-group">
        <div className="sidebar__link-group--heading">MENU</div>
        {
          menu_options.map((option) => {
            if (isHelper && !option?.helper) {
              return ""
            }

            const optionPageLink = option?.link?.replace("/my-account", "")?.replace("/", "");
            const active = optionPageLink === pageLink;

            return (
              <Link key={uuidv4()} to={option?.link} className={`sidebar__link-group--link menu ${active?"active":""}`}>
                <i className={option?.icon} />
                <p>{option?.name}</p>
              </Link>
            )
          })
        }
      </div>

      <hr className="divider" />

      <div className="sidebar__link-group support">
        <div className="sidebar__link-group--heading">SUPPORT</div>
        {
          support_options.map((option) => {
            if (isHelper && !option?.helper) {
              return ""
            }

            return (
              <a key={uuidv4()} href={option?.link} target="__blank" className="sidebar__link-group--link support">
                <i className={option?.icon} />
                <p>{option?.name}</p>
              </a>
            )
          })
        }

        {/* <div className="dark-mode">
          <span className="dark-mode__info">
            <i className="uil uil-moon" />
            <p>Dark Mode</p>
          </span>

          <div className="dark-mode__switcher">
            <div className="dark-mode__switcher--disc"></div>
          </div>
        </div> */}

        <button onClick={logoutApi} target="__blank" className="sidebar__link-group--link">
          <i className="uil uil-sign-out-alt red"/>
          <p className="red">Logout</p>
        </button>
      </div>
    </div>

    <div className="black-screen"></div>
    </>
  );
}

export default Sidebar;

import React, { useEffect, useState } from "react";
import Navbar from "../../default_pages/Navbar";
import Card from "./Card";
import Footer from "../../default_pages/Footer";
import { useNavigate } from "react-router-dom";
import { api } from "../../../backend";
import { v4 as uuidv4 } from 'uuid';

const NOTHING_FOUND = process.env.REACT_APP_NOTHING_FOUND_APPLIED;

function Main() {
  const navigate = useNavigate();
  const session_id = localStorage.getItem("session_id");
  const [application, setApplications] = useState([]);
  const size = window.innerWidth;

  const getAppliedAPI = async () => {
    try {
      const api_data = await api.get(`/profile/job-seeker?session_id=${session_id}`);
      const new_application = [];

      for (let campaign_id of (api_data.data?.user_profile?.campaigns_applied)){
        try {
          const campaign_data = await api.get(`/company/campaign/public?campaign_id=${campaign_id}&detailed=true`);
          new_application.push({ campaign: campaign_data.data?.campaign, details: campaign_data.data?.details});
        } catch (err) {
          if (err.response?.status === 401 || err.response?.status === 403) {
            localStorage.removeItem('session_id');
            localStorage.removeItem('session_type');
            navigate("/login");
          }
          else if (err.response?.status === 404) {
            continue;
          }
          else {
            alert(err.response?.data?.msg);
          }
        }
      }

      setApplications([...application, ...new_application]);
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getAppliedAPI();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar />
      <div className="applied__area">
        { application?.map((campaign) => (
          <Card key={uuidv4()} campaign={campaign} />
        ))}

        { (application.length === 0) && (
          <div className="nothing">
            <img src={
              NOTHING_FOUND + ((size <= 760)?'?tr=h-250,w-250':'?tr=h-400,w-400')
            } alt="nothing-img" />
            <h4>You haven't applied to any campaign</h4>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Main;

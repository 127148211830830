import React, { useEffect, useState } from "react";
import "../../../css/Pages/company_pages/campaigns/main.css";
import Card from "./Card";
import CampaignForm from "./CampaignForm";
import CampaignApplication from "./CampaignApplication";
import { useNavigate } from "react-router-dom";
import { api } from "../../../backend";
import { v4 as uuidv4 } from 'uuid';


const LOADING_GIF = process.env.REACT_APP_LOADING_IMG;
const CAMPAIGN_NOTHING_IMG = process.env.REACT_APP_CAMPAIGN_NOTHING_IMG;

function Main() {
  const navigate = useNavigate();
  const session_id = localStorage.getItem("session_id");
  const session_type = localStorage.getItem("session_type");
  if (session_id && (session_type !== "company" && session_type !== "helper")) {
    navigate("/");
  }
  else if (!session_id) {
    navigate("/login");
  }

  let Page_Number = 0;
  const Page_Size = 10;

  const [formMode, setFormMode] = useState({ campaign_id: "", mode: "update", visible: false });
  const [showApplication, setShowApplication] = useState({ visible: false });
  
  const [campaigns, setCampaigns] = useState([]);
  const [verify_campaigns, setVerifyCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const populateCampaigns = async (initial=false) => {
    if (initial) {
      Page_Number = 0;
      try {
        const api_call = await api.get(`/company/verify-campaign/search?session_id=${session_id}`);
        setVerifyCampaigns(api_call.data.campaigns);
      }
      catch (err) {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem('session_id');
          localStorage.removeItem('session_type');
          navigate("/login");
        } else {
          alert(err.response.data.msg);
        }
      }
    }

    Page_Number += 1;

    setLoading(true);
    setLoadMore(false);

    try {
      const api_call = await api.get(`/company/campaign?session_id=${session_id}&page_size=${Page_Size}&page_num=${Page_Number}`);
      setLoading(false);
      const campaigns_length = api_call.data.campaigns.length;
      if (( campaigns_length === 0) || (campaigns_length < Page_Size)) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }

      if (initial) {
        setCampaigns(api_call.data.campaigns);
      }
      else {
        setCampaigns([...(campaigns), ...(api_call.data.campaigns)]);
      }
    }
    catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    if (session_id && (session_type !== "company" && session_type !== "helper")) {
      navigate("/");
    }
    else if (!session_id) {
      navigate("/login");
    }

    window.scrollTo(0, 0);
    populateCampaigns(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function activateForm(campaign_id) {

    if (!campaign_id) {
      setFormMode({ campaign_id: "", mode: "create", visible: true });
    }else {
      setFormMode({ campaign_id: campaign_id, mode: "update", visible: true });
    }

    const mainScrollArea = document.querySelector(".main-area__scroll");
    mainScrollArea.classList.add("freeze");
  }

  function activeApplications(data) {
    setShowApplication({ campaignId: data._id, sectionId: data.section_id, visible: true});
    const mainScrollArea = document.querySelector(".main-area__scroll");
    mainScrollArea.classList.add("freeze");
  }

  return (
    <>
      {formMode?.visible && (
        <CampaignForm
          setVisibile={(visible) => setFormMode({ ...formMode, visible: visible })}
          mode={formMode?.mode}
          populateCampaigns={populateCampaigns}
          campaign_id={formMode?.campaign_id}
        />)
      }

      {showApplication?.visible && (
        <CampaignApplication
          hideFunc={() => setShowApplication({ visible: false })}
          campaign_id={showApplication?.campaignId}
          section_id={showApplication?.sectionId}
          getTitle={() => {
            for (let campaign of campaigns) {
              if (campaign._id === showApplication?.campaignId) {
                return campaign.campaign_title;
              }
            }
          }}
        />)
      }

      <div className="campaign">
        <div className="campaign__head">
          <h2 className="campaign__head--heading">Campaigns Manager</h2>
          <button className="campaign__head--create" onClick={() => activateForm()}>Create Campaign</button>
        </div>

        {/* <div className="campaign__filters">
          <div className="campaign__filters__search">
            <input type="text" placeholder="Search Campaing" />
            <i className="uil uil-search"></i>
          </div>

          <DropDown callback={() => {}} />
        </div> */}

        <div className="campaign__holder">
          { verify_campaigns?.map((campaign, i) => (
            <Card key={uuidv4()}
              activateForm={activateForm}
              upward_menu={i === (campaigns.length - 1)}
              data={campaign}
              verifyCampaign={true}
            />
          ))}

          { campaigns?.map((campaign, i) => (
            <Card key={uuidv4()}
              activateForm={activateForm}
              upward_menu={i === (campaigns.length - 1)}
              showApplication={activeApplications}
              data={campaign}
            />
          ))}
        </div>

        {(!loading && verify_campaigns?.length===0 && campaigns?.length === 0) && (
          <div className="nothing-found">
            <img src={
              CAMPAIGN_NOTHING_IMG + "?tr=h-280,w-280"
            } alt="nothing-found" />
            <h1>Nothing found</h1>
          </div>
        )}

        {loading && (
          <div className="loading-gif">
            <img src={LOADING_GIF + "?tr=w-100,h-100"} alt="" />
          </div>
        )}

        {loadMore && (
          <button className="load-more--btn" onClick={() => populateCampaigns()}>
            Load More
          </button>
        )}
      </div>

    </>
  );
}

export default Main;

import React, { useEffect, useState } from "react";
import "../../../css/Pages/company_pages/application/main.css";
import { api } from "../../../backend";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


const RESUME_SERVER = process.env.REACT_APP_RESUME_SERVER;
const DEFAULT_PROFILE = process.env.REACT_APP_USER_DEFAULT_PROFILE;

function Main({ application_id, user_id }) {
  const session_id = localStorage.getItem("session_id");
  const navigate = useNavigate();

  const [ user, setUser ] = useState({});
  const [ profile, setProfile ] = useState(null);
  const [ showHeadline, setShowHeadline ] = useState(false);
  const [ questions, setQuestions ] = useState([]);
  const [education, setEducation] = useState([]);
  const [ socials, setSocials ] = useState({});
  const [ showCover, setShowCover ] = useState(false);

  const getApplicationInfo = async () => {
    try {
      let api_call = {};
      if (user_id) {
        api_call = await api.get(`/company/market-place/candidate?user_id=${user_id}&session_id=${session_id}&detailed=true`);
        setUser({ info: api_call.data.candidate });
        setSocials(api_call.data.candidate?.socials);
      }
      else {
        api_call = await api.get(`/company/application?application_id=${application_id}&session_id=${session_id}&detailed=true`);
        setUser({ info: api_call.data.user_info, application: api_call.data.user_application });
        setSocials(api_call.data?.user_info?.socials);
      }
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  const displayProfile = () => {
    if (!profile && user?.info?.profile) {
      fetch(process.env.REACT_APP_IMAGE_SERVER + user?.info?.profile)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setProfile(url);
        });

      return () => {
        // Clean up the temporary URL
        URL.revokeObjectURL(profile);
      };
    }
    else {
      setProfile("")
    }
  }

  const populateQuestions = async () => {
    if (!user?.application?.campaign_id) {
      return;
    }
    try {
      const api_call = await api.get(`/company/campaign/public?detailed=false&campaign_id=${user?.application?.campaign_id}`);
      setQuestions(api_call?.data?.details.questions);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getApplicationInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sorted_edu = [];
    for (let edu of (user?.info?.education || [] )) {
      if (edu.filled) {
        if (edu.id === 0 || edu.id === 1) {
          if (edu.school) {
            sorted_edu.unshift(edu);
          }
        }
        else {
          if (edu.college && edu.course) {
            sorted_edu.unshift(edu);
          }
        }
      }
    }

    setEducation(sorted_edu);

    displayProfile();
    populateQuestions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className="application--container">
      <Helmet>
        <title>Candidate - {user?.info?.full_name || ""}</title>
        <meta name="description" content="JustFreshJobs candidate profile" />
      </Helmet>

      <div className="application">
        <div className="application__details">
          <div className="application__details--head">
            <div className="application__details--box">
              <h4 className="application__details--name">{user?.info?.full_name}</h4>
              <div className="application__details--box location">
                {(user?.info?.city || user?.info?.state || user?.info?.country) && (
                  <p className="application__details--text">
                    <i className="uil uil-location-pin-alt" />
                    {user?.info?.city && user?.info?.city + ", " }
                    {user?.info?.state && user?.info?.state + ", " }
                    {user?.info?.country}
                  </p>
                )}

                {user?.info?.relocation && (
                  <>
                    <div className="dot" />
                    <p className="application__details--relocate">Open To Relocate</p>
                  </>
                )}
              </div>
              <div className="application__details--box others">
                { user?.info?.mobile && (
                  <p className="application__details--text">
                    <i className="uil uil-phone" />+91 {user?.info?.mobile}
                  </p>
                )}

                { (user?.info?.notice_period || false) && (
                  <p className="application__details--text">
                    <i className="uil uil-exchange" />{user?.info?.notice_period} month(s)
                  </p>
                )}

                { (user?.info?.resume || false) && (
                  <a href={RESUME_SERVER + user?.info?.resume} target="_blank" rel="noreferrer" className="application__details--text">
                    <i className="uil uil-postcard" /> Resume
                  </a>
                )}
              </div>
              <div className="application__details--box">
                { (user?.info?.email || false) && (
                  <p className="application__details--text">
                    <i className="uil uil-envelope" />{user?.info?.email}
                  </p>
                )}
              </div>
            </div>

            <div className="application__details--profile">
              <img src={profile || (
                DEFAULT_PROFILE + "?tr=h-120,w-120"
              )} alt="profile" />
            </div>
          </div>

          {user?.info?.headline && (
            <>
              <div className="application__details--headline">
                {user?.info?.headline.slice(0, showHeadline?600:300).split("\n").map((word) => (
                  <React.Fragment key={uuidv4()}>
                    {word}<br />
                  </React.Fragment>
                ))} ...
              </div>
              {user?.info?.headline.length > 300 && (
                <button
                  onClick={() => setShowHeadline(!showHeadline)}
                  className="application__details--headline-view"
                >View {showHeadline?"Less":"More"}</button>
              )}
            </>
          )}

        </div>

        {(
          socials?.linkedin ||
          socials?.portfolio || 
          socials?.github || 
          socials?.youtube || 
          socials?.blog
        ) && (
          <>
            <h4 className="application__heading">Socials</h4>
            <div className="application__box no-border">
              <div className="application__box__links">
                  {socials?.linkedin && <a href={socials?.linkedin} target="_blank" rel="noreferrer">
                    <i className="uil uil-linkedin" />
                    Linkedin
                  </a>}

                  {socials?.portfolio && <a href={socials?.portfolio} target="_blank" rel="noreferrer">
                    <i className="uil uil-rocket" />
                    Portfolio
                  </a>}

                  {socials?.github && <a href={socials?.github} target="_blank" rel="noreferrer">
                    <i className="uil uil-github" />
                    Github
                  </a>}

                  {socials?.youtube && <a href={socials?.youtube} target="_blank" rel="noreferrer">
                    <i className="uil uil-youtube" />
                    Youtube
                  </a>}

                  {socials?.blog && <a href={socials?.blog} target="_blank" rel="noreferrer">
                    <i className="uil uil-blogger" />
                    Blog
                  </a>}
                </div>
            </div>
          </>
        )}

        {!user_id && <h4 className="application__heading">Cover Letter</h4> }
        {!user_id &&user?.application?.cover_letter && (
            <>
              <div className="application__sub--headline">
                {user?.application?.cover_letter.slice(0, showCover?user?.application?.cover_letter.length:300).split("\n").map((word) => (
                  <React.Fragment key={uuidv4()}>
                    {word}<br />
                  </React.Fragment>
                ))} ...
              </div>

              {user?.application?.cover_letter.length > 300 && (
                <button
                  onClick={() => setShowCover(!showCover)}
                  className="application__details--headline-view"
                >View {showCover?"Less":"More"}</button>
              )}
            </>
        )}
        

        { !user_id && <h4 className="application__heading">Questions</h4> }
        {!user_id && questions?.map((que, i) => (
          <React.Fragment key={uuidv4()}>
            <p className="application__sub--heading">{que}</p>
            <p className="application__sub--text">{user?.application?.answers[i]}</p>
          </React.Fragment>
        ))}

        { user?.info?.key_skills?.length > 0 && (
          <>
            <h4 className="application__heading">Key Skills</h4>
            <div className="application__skills">
              {user?.info?.key_skills?.map((skill) => (
                <p key={uuidv4()} className="application__skills--text">{skill}</p>
              ))}
            </div>
          </>
        )}

        { user?.info?.experience.length > 0 && <h4 className="application__heading">Experience</h4> }
        { user?.info?.experience?.map((exp) => (
          <div className="application__box" key={uuidv4()}>
            <h4 className="application__box--title">
              <span className={exp?.work_type===0?"internship":"job"}>
                {exp?.work_type===0?"Internship":"Job"}</span>
              {exp?.comp_name}
            </h4>
            { exp?.job_role && <div className="application__box--container">
              <div className="application__box--container--point">Role:</div>
              <div className="application__box--container--text">{exp?.job_role}</div>
            </div> }

            <div className="application__box--container">
              <div className="application__box--container--point">Duration:</div>
              <div className="application__box--container--text">{exp?.duration?.start} - {exp?.duration?.end}</div>
            </div>

            { (exp?.working || exp?.working === 0) && (
              <div className="application__box--container">
                <div className="application__box--container--point">Type:</div>
                <div className="application__box--container--text">
                  {exp?.working === 0 && "Office"}
                  {exp?.working === 1 && "Remote"}
                  {exp?.working === 2 && "Hybrid"}
                </div>
              </div>
            )}

            { exp?.description && <div className="application__box--container">
              <div className="application__box--container--point">Description:</div>
              <div className="application__box--container--text">{exp?.description}</div>
            </div> }
          </div>
        ))}


        { user?.info?.projects.length > 0 && <h4 className="application__heading">Projects</h4> }
        { user?.info?.projects?.map((project) => (
          <div className="application__box" key={uuidv4()}>
            <h4 className="application__box--title">{project?.name}</h4>
            <div className="application__box--container--text">{project?.description}</div>
            {(project?.live_link || project?.source_code) && (
              <div className="application__box__links">
                  { project?.live_link && <a href={project?.live_link} target="_blank" rel="noreferrer">
                    <i className="uil uil-github" />
                    Source Code
                  </a> }
                  { project?.live_link && <a href={project?.live_link} target="_blank" rel="noreferrer">
                    <i className="uil uil-rocket" />
                    Live Link
                  </a> }
              </div>
            )}
          </div>
        ))}

        { education.length > 0 && <h4 className="application__heading">Education</h4> }
        { education?.map((edu) => (
          <div className="application__box" key={uuidv4()}>
            <h4 className="application__box--title">
              {edu?.id === 0 && "Class X"}
              {edu?.id === 1 && "Class XII"}
              {edu?.id === 2 && "Graduation / Diploma"}
              {edu?.id === 3 && "Masters / Post-Graduation"}
              {edu?.id === 4 && "Doctorate / PHD"}
            </h4>

            { (edu?.stream || edu?.stream === 0) && <div className="application__box--container">
              <div className="application__box--container--point">Stream:</div>
              <div className="application__box--container--text">
                {edu?.stream === 0 && "PCM (Science)"}
                {edu?.stream === 1 && "PCB (Biology)"}
                {edu?.stream === 2 && "Commerce"}
                {edu?.stream === 3 && "Humanities"}
                {edu?.stream === 4 && "Others"}
              </div>
            </div> }

            { edu?.course && <div className="application__box--container">
              <div className="application__box--container--point">Course:</div>
              <div className="application__box--container--text">{edu?.course}</div>
            </div> }

            { (edu?.college || edu?.school)  && <div className="application__box--container">
              <div className="application__box--container--point">Institution:</div>
              <div className="application__box--container--text">{(edu?.college || edu?.school)}</div>
            </div> }

            { edu?.board && <div className="application__box--container">
              <div className="application__box--container--point">Board:</div>
              <div className="application__box--container--text">{edu?.board}</div>
            </div> }

            <div className="application__box--container">
              <div className="application__box--container--point">Duration:</div>
              <div className="application__box--container--text">{edu?.duration?.start} - {edu?.duration?.end}</div>
            </div>

            { (edu?.course_type || edu?.course_type === 0) && <div className="application__box--container">
              <div className="application__box--container--point">Course Type:</div>
              <div className="application__box--container--text">
                {edu?.course_type === 0 && "Full Time"}
                {edu?.course_type === 1 && "Part Time"}
                {edu?.course_type === 2 && "Distance Learning"}
              </div>
            </div> }

            { edu?.marks && <div className="application__box--container">
              <div className="application__box--container--point">Marks:</div>
              <div className="application__box--container--text">{edu?.marks}%</div>
            </div> }
          </div>
        ))}


        { (user?.info?.work_permit_us || user?.info?.work_permit_others?.length > 0) && (
          <>
            <h4 className="application__heading">Work Permit</h4>
            <div className="application__skills">
              { user?.info?.work_permit_us && <p className="application__skills--text">USA</p> }
              { user?.info?.work_permit_others.map((country) => (
                <p key={uuidv4} className="application__skills--text">{country}</p>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Main;

import React, { useEffect, useRef, useState } from "react";
import "../../../css/Pages/company_pages/market-place/candidate.css";
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_IMG = process.env.REACT_APP_USER_DEFAULT_PROFILE;
const IMAGE_SERVER = process.env.REACT_APP_IMAGE_SERVER;
const RESUME_SERVER = process.env.REACT_APP_RESUME_SERVER;

function Candidate({ data={}, hide=false, popuplate, market=false }) {
  const [imageUrl, setImageUrl] = useState("");
  const [education, setEducation] = useState([]);
  const experience = data?.experience || [];
  const sliced_skill = data?.key_skills?.slice(0, 6);
  const [socialAvaialbe, setSocialAvaialbe] = useState(false);
  const candidate = useRef(null);
  
  useEffect(() => {
    const sorted_edu = [];
    for (let edu of data?.education) {
      if (edu.filled) {
        if (edu.id === 0) {
          if (edu.school) {
            sorted_edu.unshift({ course: "Class X", duration: edu.duration, institute: edu.school });
          }
        }
        else if (edu.id === 1) {
          if (edu.school) {
            sorted_edu.unshift({ course: "Class XII", duration: edu.duration, institute: edu.school });
          }
        }
        else {
          if (edu.college && edu.course) {
            sorted_edu.unshift({ course: edu.course, duration: edu.duration, institute: edu.college });
          }
        }
      }
    }

    setEducation(sorted_edu);

    for (let i of Object.values(data?.socials || [])) {
      if (i !== "") {
        setSocialAvaialbe(true);
        break;
      }
    }

    if (imageUrl === "" && data?.profile) {
      fetch(IMAGE_SERVER + data?.profile)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
        });

      return () => {
        // Clean up the temporary URL
        URL.revokeObjectURL(imageUrl);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);



  return (
    <div className="candidate" ref={candidate}>
      <div className="candidate__head">
        <div className="market--wrapper">
          {!hide && <p className="candidate__head--name">{data?.full_name}</p>}
          { data?.state && (data?.relocation || data?.relocation === false)  && (
            <p className="candidate__head--location">
              {data?.state}
              {data?.relocation && (
                <span>
                  <span className="dot" />
                  Open To Relocate
                </span>
              )}
            </p>
          )}
        </div>

        {!hide && (
          <div className="candidate__img">
            <img src={imageUrl || (
              DEFAULT_IMG + "?tr=h-60,w-60"
            )} alt="profile" />
          </div>
        )}
      </div>

      <div className="candidate__body">
        <div className="candidate__info">

          {(experience?.length > 0) && (
            <div className="candidate__info__point">
              <p className="candidate__info__point--main">Experience ({data.total_exp} yrs)</p>

              <div className="candidate__desc">
                <div className="candidate__desc__info">
                  <p className="candidate__desc__info--title">{experience[0].job_role}</p>
                </div>

                <div className="candidate__desc__sub">
                  <p className="candidate__desc__sub--name">{experience[0].comp_name}</p>
                  { experience?.length>1 && <p className="candidate__desc__sub--more">+{experience.length-1} more</p>}
                </div>
              </div>
            </div>
          )}

          {(education?.length > 0) && (
            <div className="candidate__info__point">
              <p className="candidate__info__point--main">Education</p>
              <div className="candidate__desc">
                <div className="candidate__desc__info">
                  <p className="candidate__desc__info--title">{education[0]?.course}</p>
                  <span className="dot" />
                  <p className="candidate__exp__info--duration">
                    {education[0].duration?.start.split("/")[2]}-{education[0].duration?.end?.split("/")[2] || "present"}
                  </p>
                </div>

                <div className="candidate__desc__sub">
                  <p className="candidate__desc__sub--name">{education[0]?.institute}</p>
                  {education.length>1 && <p className="candidate__desc__sub--more">+{education.length-1} more</p> }
                </div>
              </div>
            </div>
          )}


          {(sliced_skill?.length > 0) && (
            <div className="candidate__info__point">
              <p className="candidate__info__point--main">Skills</p>

              <div className="candidate__desc">
                <div className="candidate__desc__info">
                  {sliced_skill?.map((skill, i) => {
                    if (i===(sliced_skill.length-1)) {
                      return <p key={uuidv4()} className="candidate__desc--skill">{skill}</p>
                    }
                    else {
                      return <React.Fragment key={uuidv4()}>
                        <p className="candidate__desc--skill">{skill}</p>
                        <div className="line" />
                      </React.Fragment>
                    }
                  })}
                </div>

                {(data?.key_skills.length>6) && <div className="candidate__desc__sub">
                  <p className="candidate__desc__sub--more">+{(data?.key_skills.length)-6} more</p>
                </div>}
              </div>
            </div>
          )}

          {!hide && socialAvaialbe && (
            <div className="candidate__info__point">
              <p className="candidate__info__point--main">Socials</p>

              <div className="candidate__desc  flex">
                {data?.socials?.portfolio && (
                  <a href={data?.socials?.portfolio} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-pagelines tooltip">
                      <span className="tooltiptext">Protfolio</span>
                    </i>
                  </a>
                )}

                {data?.socials?.linkedin && (
                  <a href={data?.socials?.linkedin} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-linkedin tooltip">
                      <span className="tooltiptext">LinkedIn</span>
                    </i>
                  </a>
                )}

                {data?.socials?.github && (
                  <a href={data?.socials?.github} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-github tooltip">
                      <span className="tooltiptext">GitHub</span>
                    </i>
                  </a>
                )}

                {data?.socials?.youtube && (
                  <a href={data?.socials?.youtube} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-youtube tooltip">
                      <span className="tooltiptext">Youtube</span>
                    </i>
                  </a>
                )}

                {data?.socials?.blog && (
                  <a href={data?.socials?.blog} className="candidate__desc--social" target="__blank">
                    <i className="uil uil-blogger tooltip">
                      <span className="tooltiptext">Blog</span>
                    </i>
                  </a>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="candidate__foot">
        <div className="candidate__foot--div">
          <a className="candidate__foot--view gray-btn" href={`/my-account/candidate/${data.user_id}`} target="_blank" rel="noreferrer">Details</a>
          { data?.resume && (
            <a className="candidate__foot--view blue-btn" href={RESUME_SERVER+(data?.resume)} target="_blank" rel="noreferrer">Resume</a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Candidate
import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";

// Dashboard Options
import Overview from "./overview/Main";
import Campaigns from "./campaigns/Main";
import Application from "./application/Main";
import Helpers from "./helpers/Main";
import Stages from "./stages/Main";
import Profile from "./profile/Main";
import MarketPlace from "./market-place/Main";

import "../../css/Pages/company_pages/mainarea.css";
import { useParams } from "react-router-dom";

function Mainarea({ show = "" }) {
  const { application_id, user_id }= useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const session_id = localStorage.getItem("session_id");
    if (!session_id) {
      navigate("/");
    }
    document.querySelector(".main-area__scroll")?.classList.remove("freeze");
  }, [show])

  return (
    <div className="dashboard">
      <Sidebar pageLink={show} />
      <div className="main-area">
        <Navbar />

        <div className="main-area__scroll">
          {show === "campaigns" && <Campaigns />}
          {show === "selection-stages" && <Stages />}
          {/* {show === "candidates" && <div>Candidates</div>} */}
          {show === "market-place" && <MarketPlace />}
          {show === "helpers" && <Helpers />}
          {show === "application" && <Application application_id={application_id} />}
          {show === "candidate" && <Application user_id={user_id} />}
          {show === "profile" && <Profile />}
          {show === "" && <Overview />}
        </div>
      </div>
    </div>
  );
}

export default Mainarea;

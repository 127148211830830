import React, { useEffect, useRef, useState } from 'react';
import "./css/Pages/job_seeker/profile/userDetails.css";
import "./css/Pages/job_seeker/profile/popup.css";
import "./css/Popup.css";
import { api } from "./backend";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';


function SkillDiv({ session_id }) {
  // choice selection box
  function ChoiceSelection({choices=[], addToList}) {
    const selectionBox = useRef(0);

    useEffect(() => {
      const results = selectionBox.current?.querySelectorAll(".result");
      results.forEach((result) => {
        result.addEventListener("click", () => {
          const name = result.innerHTML;
          const searchBox = selectionBox.current?.parentElement.querySelector("input");
          addToList(name);
          searchBox.value = "";
          selectionBox.current?.classList.remove("visible");
        })
      })
    }, [addToList])

    return (
      <div ref={selectionBox} className={`selection__box ${choices.length?" visible":""}`}>
        {choices.map((choice) => <li key={uuidv4()} className="result">{choice}</li>)}
      </div>
    )
  }

  const navigate = useNavigate();
  if (!session_id) {
    navigate("/login");
  }

  const update_api_call = async (object) => {
    try {
      await api.put(`/profile/job-seeker/details?session_id=${session_id}`, object);
    }
    catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }
  }

  // Key Skills
  const [skills, setSkills] = useState([]);
  const [skillChoices, setSkillChoices] = useState([]);

  const addSkillSet = async (name="") => {
    if (name === "") {
      name = document.querySelector(".skills-div input")?.value;
      document.querySelector(".skills-div input").value = "";
    }

    if (skills.length >= 30) {
      alert("Only 30 skills are allowed")
      return;
    }

    for(let skill of skills) {
      if (skill === name) {
        return;
      }
    }

    const new_list = [...skills, name];
    setSkills(new_list);
    localStorage.setItem("incomplete", JSON.stringify({value: false, time: Date.now()}));
    await update_api_call({ key_skills: new_list });
  }

  const searchSkill = async (keyword) => {
    if (!keyword) {
      return;
    }

    try {
      const skills = await api.get(`/site-info/skills/search?keyword=${keyword}`)
      setSkillChoices(skills.data?.skills);
    }
    catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }
  }

  const removeOption = (e, setOption, type) => {
    const proceed = window.confirm("Are you sure you want to delete it?");
    if (!proceed) {
      return;
    }

    const optionName = e.currentTarget.parentElement?.querySelector("p")?.innerText;
    setOption((prv) => { 
      let filtered = prv.filter(option => option !== optionName);
      update_api_call({ key_skills: filtered });
      return filtered;
    });
  }

  useEffect(() => {
    // Checking if clicked on choice selection box
    const body = document.querySelector('body');
    body.addEventListener("click", (e) => {
      const selectionBox = document.querySelector('.selection__box.visible');
      // if not then remove the focus from search box
      if (!e.target.classList?.contains("result") && selectionBox) {
        selectionBox?.classList?.remove("visible");
      }
    });
  }, []);

  return (
    <div className="skills-div normal-border selection-div">
      <h4 className="normal-border--title font-medium">Key Skills</h4>
      <p className="normal-border--info">
        Tell your recruiters what skills you have...
      </p>
      <div className="selection-div--showcase">
        {
          skills.map((skill) => (
            <div key={uuidv4()} className="option">
              <p>{skill}</p>
              <i className="uil uil-times" onClick={(e) => removeOption(e, setSkills, "skill")} />
            </div>
          ))
        }
      </div>

      {/* disable */}
      <div className={`selection-div--input ${skills.length === 0?"margin":""}`}>
        <input type="text" placeholder="Add new skill..." onInput={(e) => searchSkill(e.currentTarget.value)} />
        <button onClick={() => addSkillSet()}>Add</button>
        <ChoiceSelection choices={skillChoices} addToList={addSkillSet} />
      </div>

      <div className="selection-stats">{skills.length}/30</div>
    </div>
  )
}


function Resume({ session_id }) {
  const fileUpload = useRef(null);
  const navigate = useNavigate();

  const [resumeBtn, setResumeBtn] = useState(false);
  const [resumeLoad, setResumeLoad] = useState(false);

  const uploadResume = async () => {
    const file = fileUpload.current?.files[0];

    if (!file) {
      return;
    }
    const fileName = file?.name;
    const fileSize = file?.size;
    let extension = fileName?.split(".");
    extension = extension[extension.length - 1];

    if ((fileSize/1000/1000) > 2 ) {
      alert("File size must be smaller than 2mb");
      return;
    }

    if (extension !== "pdf" && extension !== "doc" && extension !== "docx") {
      alert("only pdf, doc and docx are allowed");
      return;
    }

    setResumeLoad(true);

    // Uploading ...
    const formData = new FormData();
    formData.append('file', file);

    try {
      const api_call = await api.post(`/upload/job-seeker/resume?session_id=${session_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setResumeBtn(api_call.data?.file_name);
      localStorage.setItem("incomplete", JSON.stringify({value: false, time: Date.now()}));
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }

    setResumeLoad(false);
  }


  const deleteResume = async () => {
    try {
      await api.delete(`/upload/job-seeker/resume?session_id=${session_id}`);
      setResumeBtn(false);
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }

  return (
    <div className="profile__div resume">
      <h4 className="profile__div--title">Resume</h4>
      <h4 className="profile__div--info">Only pdf files are accepted</h4>
      <div className="wrapper">
        <span>
          <button className="profile__div--button"
            onClick={() => fileUpload.current.click()}
          ><i className="uil uil-upload-alt" />{resumeBtn?"Re-":""}Upload Resume</button>
          <input ref={fileUpload} type="file" style={{ display: "none"}} onChange={uploadResume} name="resume-upload" />
        </span>

        <div className="wrapper--btns">
          { resumeLoad && <img src={process.env.REACT_APP_LOADING_IMG + "?tr=h-20,w-20"} alt="loading" /> }
          { resumeBtn && 
            (<>
              <a className="view-btn" href={process.env.REACT_APP_RESUME_SERVER + resumeBtn} target="_blank" rel="noreferrer">
                <i className="uil uil-eye" />
              </a>

              <button id="remove-resume" onClick={deleteResume}>
                <i className="uil uil-times-circle" />
              </button>
            </>)
          }
        </div>
      </div>
    </div>
  )
}



function Popup() {
  const session_id = localStorage.getItem('session_id');
  const session_type = localStorage.getItem('session_type');
  const [hidden, setHidden] = useState(true);

  const isProfileSet = async () => {
    const storage = JSON.parse(localStorage.getItem("incomplete") || "{}");

    if (storage?.value === false) {
      const expiryHour = (Date.now() - storage?.time)/1000/60/60;
      if (expiryHour < 24) {
        setHidden(true);
        return;
      }
    }
    else if (storage?.value === true) {
      setHidden(false);
    }
    else {
      const responce = await api.get(`/profile/job-seeker?session_id=${session_id}`);
      if (!responce) {
        return setHidden(true);
      }
      const userProfile = responce?.data?.user_profile;
      if (!userProfile) {
        setHidden(true);
      } else {
        if (userProfile?.key_skills?.length > 0 || userProfile?.resume) {
          localStorage.setItem("incomplete", JSON.stringify({value: false, time: Date.now()}));
          setHidden(true);
        } else {
          localStorage.setItem("incomplete", JSON.stringify({ value: true }));
          setHidden(false);
        }
      }
    }
  }

  useEffect(() => {
    if (session_id && (session_type === "job_seeker")) {
      isProfileSet();
    }

    document.body.addEventListener("click", (e) => {
      if (e.target.classList.contains("popup-skill")) {
        setHidden(true);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
    {!hidden && session_id && (session_type === "job_seeker") && 
      <div className='popup-skill fixed w-screen h-screen z-50 grid place-items-center'>
        <div className="bg-white outer">
          <SkillDiv session_id={session_id} />
          <Resume session_id={session_id} />

          <div className='btn-div w-full grid grid-cols-2 p-4 gap-6 mt-4'>
            <button
              className='bg-yellow-400 text-yellow-950 text-lg font-medium p-1 py-2 border-2 rounded-md border-yellow-700 border-solid'
              onClick={() => {setHidden(true)}}>
                Done
            </button>
            <Link to={"/user/profile"} className='bg-gray-200 text-center cursor-pointer py-2 text-gray-900 text-lg font-medium p-1 border-2 rounded-md border-gray-700 border-solid'>Go To Profile</Link>
          </div>
        </div>
      </div>
    }
    </>
  )
}

export default Popup;

import React, { useEffect, useRef, useState } from "react";
import "../../../css/Pages/job_seeker/profile/popup.css";
import { api } from "../../../backend";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const locations = JSON.parse(process.env.REACT_APP_LOCATION);

function getLocation(query) {
  const filtered = [];

  if (!query) {
    return filtered;
  }

  for (let location of locations) {
    if (location.toLowerCase().indexOf(query.toLowerCase()) === 0) {
      filtered.push(location);

      if (filtered.length === 5) {
        break;
      }
    }
  }

  return filtered;
}


function InfoInputs({ hidePopup, data, setResumeLink }) {
  const fileUpload = useRef(null);

  const navigate = useNavigate();
  const session_id = localStorage.getItem('session_id');
  if (!session_id) {
    navigate("/login");
  }

  const [haveUs, setHaveUs] = useState(false);
  const [usQues, setUsQues] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  const [resumeBtn, setResumeBtn] = useState(false);
  const [resumeLoad, setResumeLoad] = useState(false);

  const fillUsQues = async () => {
    try {
      const api_data = await api.get("/site-info/us-ques");
      setUsQues(api_data?.data?.us_ques);
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }
  }

  const uploadResume = async () => {
    const file = fileUpload.current?.files[0];

    if (!file) {
      return;
    }
    const fileName = file?.name;
    const fileSize = file?.size;
    let extension = fileName?.split(".");
    extension = extension[extension.length - 1];

    if ((fileSize/1000/1000) > 2 ) {
      alert("File size must be smaller than 2mb");
      return;
    }

    if (extension !== "pdf" && extension !== "doc" && extension !== "docx") {
      alert("only pdf, doc and docx are allowed");
      return;
    }

    setResumeLoad(true);

    // Uploading ...
    const formData = new FormData();
    formData.append('file', file);

    try {
      const api_call = await api.post(`/upload/job-seeker/resume?session_id=${session_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setResumeLink(api_call.data?.file_name);
      setResumeBtn(api_call.data?.file_name);
      localStorage.setItem("incomplete", JSON.stringify({value: false, time: Date.now()}));
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data.msg);
      }
    }

    setResumeLoad(false);
  }


  const deleteResume = async () => {
    try {
      await api.delete(`/upload/job-seeker/resume?session_id=${session_id}`);
      setResumeLink(false);
      setResumeBtn(false);
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }

  useEffect(() => {
    setResumeBtn(data?.resume);

    if (data?.work_permit_us) {
      setHaveUs(true);
      document.getElementById("permit-us-yes").checked = true;
    }
    fillUsQues();

    const state = document.querySelector("#state");
    state.addEventListener("input", () => {
      const choices = getLocation(state.value);
      if (choices.length > 0) {
        setStateOptions(getLocation(state.value));
        state.nextSibling.classList.add("visible");
      }
      else {
        state.nextSibling.classList.remove("visible");
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  const saveFunction = async (e) => {

    if (e.currentTarget.classList?.contains("disabled")) {
      return
    }

    e.currentTarget.classList?.add("disabled")
    const name = document.querySelector(".profile__div.name input");
    const mobile = document.querySelector(".profile__div.mobile input");
    const city = document.querySelector(".profile__div.city input");
    const state = document.querySelector(".profile__div.state input");
    const notice_period = document.querySelector(".profile__div.notice-period select");

    let work_permit_us = "";
    if (haveUs) {
      work_permit_us = document.getElementById("us-permit")?.value;
    }

    let relocation = false;
    if (document.getElementById("relocate-yes")?.checked) {
      relocation = true;
    }

    let gender = undefined;
    if (document.getElementById("gender-male")?.checked) {
      gender = 0;
    }
    else if (document.getElementById("gender-female")?.checked) {
      gender = 1;
    }
    else if (document.getElementById("gender-other")?.checked) {
      gender = 2;
    }


    const portfolio = document.querySelector(".social-showcase__link.portfolio input");
    const linkedin = document.querySelector(".social-showcase__link.linkedin input");
    const github = document.querySelector(".social-showcase__link.github input");
    const youtube = document.querySelector(".social-showcase__link.youtube input");
    const blog = document.querySelector(".social-showcase__link.blog input");

    try {
      await api.put(`/register/job-seeker?session_id=${session_id}`, {
        full_name: name.value || "",
        mobile: (mobile.value === data.mobile)?"": mobile.value
      });

      await api.put(`/profile/job-seeker/details?session_id=${session_id}`, {
        socials: {
          linkedin: linkedin?.value,
          portfolio: portfolio?.value,
          github: github?.value,
          youtube: youtube?.value,
          blog: blog?.value,
        },
        work_permit_us: work_permit_us,
        city: city?.value,
        state: state?.value,
        country: "India",
        notice_period: parseInt(notice_period.value), // 0/1/2/3/4/5
        relocation: relocation,
        gender
      });


      hidePopup();
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }


  return (
    <>
      <h4 className="popup-heading">Basic details</h4>

      <div className="profile__div name">
        <h4 className="profile__div--title">Name</h4>
        <input
          type="text"
          placeholder="Enter Your Name"
          className="input-outline"
          defaultValue={data?.full_name || ""}
        />
      </div>

      <div className="profile__div gender">
        <h4 className="profile__div--title">Gender</h4>
        <div className="flex-wrapper">
          <span>
            {(data?.gender === 0)?
              (<input type="radio" id="gender-male" name="gender" defaultChecked />):
              (<input type="radio" id="gender-male" name="gender" />)
            }
            <label htmlFor="gender-male">Male</label>
          </span>
          <span>
            {(data?.gender === 1)?
              (<input type="radio" id="gender-female" name="gender" defaultChecked />):
              (<input type="radio" id="gender-female" name="gender" />)
            }
            <label htmlFor="gender-female">Female</label>
          </span>
          <span>
            {(data?.gender === 2)?
              (<input type="radio" id="gender-other" name="gender" defaultChecked />):
              (<input type="radio" id="gender-other" name="gender" />)
            }
            <label htmlFor="gender-other">Others</label>
          </span>
        </div>
      </div>


      <div className="profile__div mobile">
        <h4 className="profile__div--title">Mobile number</h4>
        <div className="pretext-container">
          <p className="pretext">+91</p>
          <input type="number" placeholder="Mobile Number" defaultValue={data?.mobile || ""} />
        </div>
      </div>


      <div className="profile__div city">
        <h4 className="profile__div--title">City</h4>
        <input
          type="text"
          placeholder="Enter City Name"
          className="input-outline"
          defaultValue={data?.city || ""}
        />
      </div>


      <div className="profile__div state">
        <h4 className="profile__div--title">State</h4>
        <input
          type="text"
          placeholder="Enter State Name"
          className="input-outline"
          id="state"
          defaultValue={data?.state || ""}
        />
        <ul className="filters__choice__box__selection">
          {stateOptions.map((choice) => (
            <li
              key={uuidv4()}
              onClick={(e) => {
                document.querySelector("#state").value = choice;
                e.currentTarget.parentElement.classList.remove("visible");
              }}
              className="result">{choice}</li>
          ))}
        </ul>
      </div>


      <div className="profile__div notice-period">
        <h4 className="profile__div--title">Availability to join</h4>

        <div className="profile__div--selection">
          <select id="notice-period">
            {(data?.notice_period === 0)?
              (<option value="0" selected>Available Now</option>):
              (<option value="0">Available Now</option>)
            }

            {(data?.notice_period === 1)?
              (<option value="1" selected>1 month</option>):
              (<option value="1">1 month</option>)
            }

            {(data?.notice_period === 2)?
              (<option value="2" selected>2 months</option>):
              (<option value="2">2 months</option>)
            }

            {(data?.notice_period === 3)?
              (<option value="3" selected>3 months</option>):
              (<option value="3">3 months</option>)
            }

            {(data?.notice_period === 4)?
              (<option value="4" selected>more than 3 months</option>):
              (<option value="4">more than 3 months</option>)
            }
          </select>
          <i className="uil uil-angle-down" />
        </div>
      </div>


      <div className="profile__div resume">
        <h4 className="profile__div--title">Resume</h4>
        <h4 className="profile__div--info">Only pdf, doc, docx files are accepted</h4>
        <div className="wrapper">
          <span>
            <button className="profile__div--button"
              onClick={() => fileUpload.current.click()}
            ><i className="uil uil-upload-alt" />{resumeBtn?"Re-":""}Upload Resume</button>
            <input ref={fileUpload} type="file" style={{ display: "none"}} onChange={uploadResume} name="resume-upload" />
          </span>

          <div className="wrapper--btns">
            { resumeLoad && <img src={process.env.REACT_APP_LOADING_IMG + "?tr=h-20,w-20"} alt="loading" /> }
            { resumeBtn && 
              (<>
                <a className="view-btn" href={process.env.REACT_APP_RESUME_SERVER + resumeBtn} target="_blank" rel="noreferrer">
                  <i className="uil uil-eye" />
                </a>

                <button id="remove-resume" onClick={deleteResume}>
                  <i className="uil uil-times-circle" />
                </button>
              </>)
            }
          </div>
        </div>
      </div>


      <div className="profile__div us-permit">
        <h4 className="profile__div--title">Do you have US work permit?</h4>
        <div className="flex-wrapper">
          <span>
            <input type="radio" id="permit-us-yes" name="permit-us-que" onClick={() => setHaveUs(true)} />
            <label htmlFor="permit-us-yes">Yes</label>
          </span>
          <span>
            <input type="radio" id="permit-us-no" name="permit-us-que" defaultChecked onClick={() => setHaveUs(false)} />
            <label htmlFor="permit-us-no">No</label>
          </span>
        </div>
      </div>

      { haveUs && (
        <div className="profile__div notice-period">
          <h4 className="profile__div--title">US Permit Type</h4>

          <div className="profile__div--selection">
            <select id="us-permit">
              {usQues.map((obj) => {
                if (obj?._id === data?.work_permit_us) {
                  return <option value={obj?._id} key={uuidv4()} selected>{obj?.que}</option>
                } else {
                  return <option value={obj?._id} key={uuidv4()}>{obj?.que}</option>
                }
              })}
            </select>
            <i className="uil uil-angle-down" />
          </div>
        </div>)
      }


      <div className="profile__div us-permit">
        <h4 className="profile__div--title">Relocate</h4>
        <h4 className="profile__div--info">Are you able to reloacte based on job requirements?</h4>
        <div className="flex-wrapper">
          <span>
            { data?.relocation?
              (<input type="radio" id="relocate-yes" name="relocate" defaultChecked />):
              (<input type="radio" id="relocate-yes" name="relocate" />)
            }
            <label htmlFor="relocate-yes">Yes</label>
          </span>
          <span>
            { data?.relocation?
              (<input type="radio" id="relocate-no" name="relocate"/>):
              (<input type="radio" id="relocate-no" name="relocate" defaultChecked/>)
            }
            <label htmlFor="relocate-no">No</label>
          </span>
        </div>
      </div>


      <div className="profile__div">
        <h4 className="profile__div--title">Social Links</h4>
        <div className="social-showcase">
          <div className="social-showcase__link portfolio">
            <p className="social-showcase__link--type">Portfolio</p>
            <input
              type="text"
              className="social-showcase__link--input"
              placeholder="Enter link"
              defaultValue={data?.socials?.portfolio || ""}
            />
          </div>

          <div className="social-showcase__link linkedin">
            <p className="social-showcase__link--type">LinkedIn</p>
            <input
              type="text"
              className="social-showcase__link--input"
              placeholder="Enter link"
              defaultValue={data?.socials?.linkedin || ""}
            />
          </div>

          <div className="social-showcase__link github">
            <p className="social-showcase__link--type">GitHub</p>
            <input
              type="text"
              className="social-showcase__link--input"
              placeholder="Enter link"
              defaultValue={data?.socials?.github || ""}
            />
          </div>

          <div className="social-showcase__link youtube">
            <p className="social-showcase__link--type">YouTube</p>
            <input
              type="text"
              className="social-showcase__link--input"
              placeholder="Enter link"
              defaultValue={data?.socials?.youtube || ""}
            />
          </div>

          <div className="social-showcase__link blog">
            <p className="social-showcase__link--type">Blog</p>
            <input
              type="text"
              className="social-showcase__link--input"
              placeholder="Enter link"
              defaultValue={data?.socials?.blog || ""}
            />
          </div>
        </div>
      </div>

      <div className="profile__btns">
        <button className="profile__btns--save" onClick={(e) => saveFunction(e)}>Save</button>
        <button className="profile__btns--cancel" onClick={() => hidePopup(false)}>Cancel</button>
      </div>
    </>
  );
}


function ExperienceInputs({ hidePopup, action, id, data }) {

  const navigate = useNavigate();
  const session_id = localStorage.getItem('session_id');
  if (!session_id) {
    navigate("/login");
  }

  useEffect(() => {
    const stats = document.querySelectorAll(".profile__div .profile__div--stats");
    for(let stat of stats) {
      const inputField = stat?.parentElement?.querySelector("input");
      if (inputField) {
        inputField.addEventListener("keyup", () => {
          if (inputField.value.length > parseInt(stat.dataset.size)) {
            inputField.value = inputField.value.slice(0, parseInt(stat.dataset.size));
          }
          stat.querySelector("span").innerText = inputField.value.length;
        });
        continue;
      }

      const textArea = stat?.parentElement?.querySelector("textarea");
      if (textArea) {
        textArea.addEventListener("keyup", () => {
          if (textArea.value.length > parseInt(stat.dataset.size)) {
            textArea.value = textArea.value.slice(0, parseInt(stat.dataset.size));
          }
          stat.querySelector("span").innerText = textArea.value.length;
        });
      }
    }

    if (action === "update") {
      if (data?.work_type === 0){
        document.getElementById("work-internship").checked = true; 
      } else {
        document.getElementById("work-job").checked = true; 
      }

      const company_name = document.querySelector(".profile__div.company input");
      company_name.value = data?.comp_name || "";

      const position = document.querySelector(".profile__div.position input");
      position.value = data?.job_role || "";

      const description = document.querySelector(".profile__div.description textarea");
      description.value = data?.description || "";

      if (data?.working === 0) {
        document.getElementById("office-loc").checked = true;
      }
      else if (data?.working === 1) {
        document.getElementById("remote-loc").checked = true;
      }
      else if (data?.working === 2) {
        document.getElementById("hybrid-loc").checked = true;
      }

      const start = data?.duration?.start?.split("/");
      document.getElementById("start-date").value = `${start[2]}-${start[1]}`;

      let end = data?.duration?.end;
      if (end !== "present") {
        end = end.split("/");
        document.getElementById("end-date").value = `${end[2]}-${end[1]}`;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveFunction = async () => {
    const company_name = document.querySelector(".profile__div.company input");
    const position = document.querySelector(".profile__div.position input");
    const description = document.querySelector(".profile__div.description textarea");

    let work_type = undefined;
    if (document.getElementById("work-internship")?.checked) {
      work_type = 0;
    }
    else if (document.getElementById("work-job")?.checked) {
      work_type = 1;
    }

    let working = undefined;
    if (document.getElementById("remote-loc")?.checked) {
      working = 1;
    }
    else if (document.getElementById("office-loc")?.checked) {
      working = 0
    }
    else if (document.getElementById("hybrid-loc")?.checked) {
      working = 2
    }

    let start = document.getElementById("start-date")?.value;
    if (!start) {
      alert("Please enter duration");
    }
    start = start?.split("-");
    start = `01/${start[1]}/${start[0]}`;

    let end = document.getElementById("end-date")?.value;
    if (end !== "") {
      end = end.split("-");
      end = `01/${end[1]}/${end[0]}`;
    }
    else if (end === "") {
      end = "present";
    }

    try {
      if (action === "update") {
        await api.put(`/profile/job-seeker/experience?position=${id}&session_id=${session_id}`, {
          comp_name: company_name.value,
          duration: {start, end},
          job_role: position.value,
          description: description.value,
          work_type,
          working
        });
      }
      else {
        await api.post(`/profile/job-seeker/experience?session_id=${session_id}`, {
          comp_name: company_name.value,
          duration: {start, end},
          job_role: position.value,
          description: description.value,
          work_type,
          working
        });
      }
      await hidePopup();
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }

  return (
    <>
      <h4 className="popup-heading">{action==="add"?"Add":"Update"} Work Experience</h4>

      <div className="profile__div">
        <h4 className="profile__div--title">Work Type
        </h4>
        <div className="flex-wrapper">
          <span>
            <input type="radio" id="work-internship" name="experience-type" />
            <label htmlFor="work-internship">Internship</label>
          </span>

          <span>
            <input type="radio" id="work-job" name="experience-type" />
            <label htmlFor="work-job">Job</label>
          </span>
        </div>
      </div>

      <div className="profile__div company">
        <h4 className="profile__div--title">Company Name</h4>
        <input
          type="text"
          placeholder="Enter Your Company Name"
          className="input-outline"
        />
        <p className="profile__div--stats" data-size="80"><span>0</span>/80</p>
      </div>

      <div className="profile__div position">
        <h4 className="profile__div--title">Position</h4>
        <input
          type="text"
          placeholder="Eg: Software Developer"
          className="input-outline"
        />
        <p className="profile__div--stats" data-size="30"><span>0</span>/30</p>
      </div>

      <div className="profile__div">
        <h4 className="profile__div--title">Location
        </h4>

        <div className="flex-wrapper">
          <span>
            <input type="radio" id="remote-loc" name="location-type" />
            <label htmlFor="remote-loc">Remote</label>
          </span>

          <span>
            <input type="radio" id="office-loc" name="location-type" />
            <label htmlFor="office-loc">Office</label>
          </span>

          <span>
            <input type="radio" id="hybrid-loc" name="location-type" />
            <label htmlFor="hybrid-loc">Hybrid</label>
          </span>
        </div>
      </div>

      <div className="profile__div">
        <h4 className="profile__div--title">Duration</h4>
        <h4 className="profile__div--info">If you are still working leave the end date</h4>

        <div className="flex-wrapper">
          <span>
            <input type="month" id="start-date" name="date-type" />
            <label htmlFor="start-date">Start</label>
          </span>
          
          <span>
            <input type="month" id="end-date" name="date-type" />
            <label htmlFor="end-date">End</label>
          </span>
        </div>
      </div>

      <div className="profile__div description">
        <h4 className="profile__div--title">Description</h4>
        <textarea className="profile__div--textarea" placeholder="What responsibilities you had?" />
        <p className="profile__div--stats" data-size="150"><span>0</span>/150</p>
      </div>

      <div className="profile__btns">
        <button className="profile__btns--save" onClick={saveFunction}>
          {action==="add"?"Add":"Update"}
        </button>
        <button className="profile__btns--cancel" onClick={() => hidePopup(false)}>Cancel</button>
      </div>
    </>
  )
}


function EducationInputs({ hidePopup, edu_type, action, education }) {
  const navigate = useNavigate();
  const session_id = localStorage.getItem('session_id');
  if (!session_id) {
    navigate("/login");
  }

  let isSchoolType = true;
  if (edu_type !== "x" && edu_type !== "xii") {
    isSchoolType = false;
  }

  let edu_object = {};
  if (edu_type === "x") {
    edu_object = education[0];
  }
  else if (edu_type === "xii") {
    edu_object = education[1];
  }
  else if (edu_type === "deploma") {
    edu_object = education[2];
  }
  else if (edu_type === "masters") { 
    edu_object = education[3];
  }
  else {
    edu_object = education[4];
  }

  useEffect(() => {
    const stats = document.querySelectorAll(".profile__div .profile__div--stats");
    for(let stat of stats) {
      const inputField = stat.parentElement.querySelector("input");
      if (inputField) {
        inputField.addEventListener("keyup", () => {
          if (inputField.value.length > parseInt(stat.dataset.size)) {
            inputField.value = inputField.value.slice(0, parseInt(stat.dataset.size));
          }
          stat.querySelector("span").innerText = inputField.value.length;
        });
        continue;
      }

      const textArea = stat.parentElement.querySelector("textarea");
      if (textArea) {
        textArea.addEventListener("keyup", () => {
          if (textArea.value.length > parseInt(stat.dataset.size)) {
            textArea.value = textArea.value.slice(0, parseInt(stat.dataset.size));
          }
          stat.querySelector("span").innerText = textArea.value.length;
        });
      }
    }

    const start = edu_object?.duration?.start?.split("/");
    document.getElementById("start-date").value = `${start[2]}-${start[1]}`;

    let end = edu_object?.duration?.end;
    if (end !== "present") {
      end = end.split("/");
      document.getElementById("end-date").value = `${end[2]}-${end[1]}`;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const submitFunction = async () => {
    const update_obj = {};

    if (isSchoolType) {
      update_obj.school = document.querySelector(".profile__div.institution input")?.value;
      update_obj.board = document.querySelector(".profile__div.board input")?.value;

      // setting stream
      if (document.getElementById("s1")?.checked) {
        update_obj.stream = 0;
      }
      else if (document.getElementById("s2")?.checked) {
        update_obj.stream = 1;
      }
      else if (document.getElementById("s3")?.checked) {
        update_obj.stream = 2;
      }
      else if (document.getElementById("s4")?.checked) {
        update_obj.stream = 3;
      }
      else if (document.getElementById("s5")?.checked) {
        update_obj.stream = 4;
      } 
    } else {
      update_obj.college = document.querySelector(".profile__div.institution input")?.value;
      update_obj.course = document.querySelector(".profile__div.course input")?.value;

      // Course Type
      if (document.getElementById("full-time")?.checked) {
        update_obj.course_type = 0;
      }
      else if (document.getElementById("part-time")?.checked) {
        update_obj.course_type = 1;
      }
      else if (document.getElementById("distance")?.checked) {
        update_obj.course_type = 2;
      }
    }

    update_obj.id = edu_object?.id
    update_obj.marks = document.querySelector(".profile__div.marks input")?.value


    let start = document.getElementById("start-date")?.value;
    if (!start) {
      alert("Please enter duration");
    }
    start = start?.split("-");
    start = `01/${start[1]}/${start[0]}`;

    let end = document.getElementById("end-date")?.value;
    if (end !== "") {
      end = end.split("-");
      end = `01/${end[1]}/${end[0]}`;
    }
    else if (end === "") {
      end = "present";
    }

    update_obj.duration = { start, end };

    try {
      await api.put(`/profile/job-seeker/education?session_id=${session_id}`, update_obj);
      hidePopup();
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }

  const removeFunction = async () => {
    const proceed = window.confirm("Are you sure you want remove it?");
    if (!proceed) {
      return;
    }

    try {
      await api.delete(`/profile/job-seeker/education?id=${edu_object.id}&session_id=${session_id}`);
      hidePopup();
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }

  return (
    <>
    <h4 className="popup-heading">
      <span>
        {action==="add"?"Add":"Update"} {edu_type?.toUpperCase()} Education
      </span>

      { (action === "update") && (
          <button className="remove-edu" onClick={removeFunction}>
            <i className="uil uil-trash-alt" />
          </button>
      )}
    </h4>
    <div className="profile__div institution">
      <h4 className="profile__div--title">
        {isSchoolType?"School Name":"Institution Name"}
      </h4>
      <input
        type="text"
        placeholder={`Enter Your ${isSchoolType?"School Name":"Institution Name"}`}
        className="input-outline"
        defaultValue={edu_object?.school || edu_object?.college || ""}
      />
      <p className="profile__div--stats" data-size="150"><span>0</span>/150</p>
    </div>

    {isSchoolType?(
      <div className="profile__div board">
        <h4 className="profile__div--title">Board Name</h4>
        <input
          type="text"
          placeholder="Enter Your Board Name"
          className="input-outline"
          defaultValue={edu_object?.board || ""}
        />
        <p className="profile__div--stats" data-size="80"><span>0</span>/80</p>
      </div>):
      (<div className="profile__div course">
        <h4 className="profile__div--title">Course Name</h4>
        <input
          type="text"
          placeholder="Enter Your Course Name"
          className="input-outline"
          defaultValue={edu_object?.course || ""}
        />
        <p className="profile__div--stats" data-size="120"><span>0</span>/120</p>
      </div>)
    }

    {edu_type==="xii" && (
      <div className="profile__div stream">
        <h4 className="profile__div--title">Stream</h4>
        <div className="flex-wrapper">
          <span>
            { (edu_object?.stream === 0) ?
              ( <input type="radio" id="s1" name="course-type" defaultChecked /> ):
              ( <input type="radio" id="s1" name="course-type" /> )
            }
            <label htmlFor="s1">Science (PCM)</label>
          </span>

          <span>
            { (edu_object?.stream === 1) ?
              ( <input type="radio" id="s2" name="course-type" defaultChecked /> ):
              ( <input type="radio" id="s2" name="course-type" /> )
            }
            <label htmlFor="s2">Biology (PCB)</label>
          </span>

          <span>
            { (edu_object?.stream === 2) ?
              ( <input type="radio" id="s3" name="course-type" defaultChecked /> ):
              ( <input type="radio" id="s3" name="course-type" /> )
            }
            <label htmlFor="s3">Commerce</label>
          </span>

          <span>
            { (edu_object?.stream === 3) ?
              ( <input type="radio" id="s4" name="course-type" defaultChecked /> ):
              ( <input type="radio" id="s4" name="course-type" /> )
            }
            <label htmlFor="s4">Humanities</label>
          </span>

          <span>
            { (edu_object?.stream === 4) ?
              ( <input type="radio" id="s5" name="course-type" defaultChecked /> ):
              ( <input type="radio" id="s5" name="course-type" /> )
            }
            <label htmlFor="s5">N/A</label>
          </span>
        </div>
      </div>
    )}

    {!isSchoolType && (
      <div className="profile__div">
        <h4 className="profile__div--title">Course Type</h4>
        <div className="flex-wrapper">
          <span>
            { (edu_object?.course_type === 0) ?
              ( <input type="radio" id="full-time" name="course-type" defaultChecked /> ):
              ( <input type="radio" id="full-time" name="course-type" /> )
            }
            <label htmlFor="full-time">Full Time</label>
          </span>

          <span>
            { (edu_object?.course_type === 1) ?
              ( <input type="radio" id="part-time" name="course-type" defaultChecked /> ):
              ( <input type="radio" id="part-time" name="course-type" /> )
            }
            <label htmlFor="part-time">Part Time</label>
          </span>

          <span>
            { (edu_object?.course_type === 2) ?
              ( <input type="radio" id="distance" name="course-type" defaultChecked /> ):
              ( <input type="radio" id="distance" name="course-type" /> )
            }
            <label htmlFor="distance">Distance Learning</label>
          </span>
        </div>
      </div>)
    }

    <div className="profile__div marks">
      <h4 className="profile__div--title">Marks</h4>
      <p className="profile__div--info">Leave if you are currently doing it</p>
      <div className="pretext-container">
        <p className="pretext">%</p>
        <input type="number" placeholder="Enter Percentage" defaultValue={ edu_object?.marks || "" } />
      </div>
    </div>

    <div className="profile__div">
      <h4 className="profile__div--title">Duration</h4>
      <h4 className="profile__div--info">If you are still persuing leave the end date</h4>

      <div className="flex-wrapper">
        <span>
          <input type="month" id="start-date" name="date-type" />
          <label htmlFor="start-date">Start</label>
        </span>

        <span>
          <input type="month" id="end-date" name="date-type" />
          <label htmlFor="end-date">End</label>
        </span>
      </div>
    </div>

    <div className="profile__btns">
      <button className="profile__btns--save" onClick={submitFunction}>Save</button>
      <button className="profile__btns--cancel" onClick={() => hidePopup(false)}>Cancel</button>
    </div>
    </>
  )
}


function ProjectInputs({ hidePopup, action, id, project }) {
  const navigate = useNavigate();
  const session_id = localStorage.getItem('session_id');
  if (!session_id) {
    navigate("/login");
  }

  useEffect(() => {
    const stats = document.querySelectorAll(".profile__div .profile__div--stats");
    for(let stat of stats) {
      const inputField = stat?.parentElement?.querySelector("input");
      if (inputField) {
        inputField.addEventListener("keyup", () => {
          if (inputField.value.length > parseInt(stat.dataset.size)) {
            inputField.value = inputField?.value.slice(0, parseInt(stat.dataset.size));
          }
          stat.querySelector("span").innerText = inputField?.value.length;
        });
        continue;
      }

      const textArea = stat?.parentElement?.querySelector("textarea");
      if (textArea) {
        textArea.addEventListener("keyup", () => {
          if (textArea.value.length > parseInt(stat.dataset.size)) {
            textArea.value = textArea?.value.slice(0, parseInt(stat.dataset.size));
          }
          stat.querySelector("span").innerText = textArea?.value.length;
        });
      }
    }

    if (action === "update") {
      const project_title = document.querySelector(".profile__div.titile input");
      project_title.value = project.name || "";
  
      const project_description = document.querySelector(".profile__div.desc textarea");
      project_description.value = project.description || "";
  
      const code_link = document.querySelector(".social-showcase__link.code input");
      code_link.value = project.source_code || "";
  
      const live_link = document.querySelector(".social-showcase__link.live input");
      live_link.value = project.live_link || "";
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveFunction = async () => {
    const project_title = document.querySelector(".profile__div.titile input");
    const project_description = document.querySelector(".profile__div.desc textarea");
    const code_link = document.querySelector(".social-showcase__link.code input");
    const live_link = document.querySelector(".social-showcase__link.live input");

    try {
      if (action === "update") {
        await api.put(`/profile/job-seeker/project?position=${id}&session_id=${session_id}`, {
          name: project_title?.value,
          description: project_description?.value,
          live_link: live_link?.value,
          source_code: code_link?.value
        });
      }
      else {
        await api.post(`/profile/job-seeker/project?session_id=${session_id}`, {
          name: project_title?.value,
          description: project_description?.value,
          live_link: live_link?.value,
          source_code: code_link?.value
        });
      }
      await hidePopup();
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response?.data?.msg);
      }
    }
  }

  return (
    <>
      <h4 className="popup-heading">{action==="add"?"Add":"Update"} Project</h4>
      <div className="profile__div titile">
        <h4 className="profile__div--title">Project Title</h4>
        <input
          type="text"
          placeholder="Enter project title"
          className="input-outline"
        />
        <p className="profile__div--stats" data-size="70"><span>0</span>/70</p>
      </div>

      <div className="profile__div desc">
        <h4 className="profile__div--title">Description</h4>
        <textarea className="profile__div--textarea" placeholder="Type about your project ..."></textarea>
        <p className="profile__div--stats" data-size="300"><span>0</span>/300</p>
      </div>

      <div className="profile__div">
        <h4 className="profile__div--title">Links</h4>
        <div className="social-showcase">
          <div className="social-showcase__link code">
            <p className="social-showcase__link--type">Code</p>
            <input
              type="text"
              className="social-showcase__link--input"
              placeholder="Enter source code location"
            />
          </div>

          <div className="social-showcase__link live">
            <p className="social-showcase__link--type">Live Link</p>
            <input
              type="text"
              className="social-showcase__link--input"
              placeholder="Enter project's live link"
            />
          </div>
        </div>
      </div>

      <div className="profile__btns">
        <button className="profile__btns--save" onClick={saveFunction}>
          {action==="add"?"Add":"Update"}
        </button>
        <button className="profile__btns--cancel" onClick={() => hidePopup(false)}>Cancel</button>
      </div>
    </>
  )
}


function Popup({ input_type, edu_type = "x", hidePopup, action="add", id="", data="", setResumeLink="" }) {
  useEffect(() => {
    const popup = document.querySelector(".profile-popup");
    popup.addEventListener("click", (e) => {
      if (e.target.classList?.contains("profile-popup")){
        hidePopup(false);
      }
    });
    document.body?.classList?.add("fixed");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="profile-popup">
      <div className="main-popup">
        {input_type === "info" && <InfoInputs hidePopup={hidePopup} data={data} setResumeLink={setResumeLink} />}
        {input_type === "experience" && <ExperienceInputs hidePopup={hidePopup} action={action} id={id} data={data} />}
        {input_type === "education" && <EducationInputs edu_type={edu_type} action={action} hidePopup={hidePopup} education={data} />}
        {input_type === "project" && <ProjectInputs hidePopup={hidePopup} action={action} id={id} project={data} />}
      </div>
    </div>
  );
}

export default Popup;

import React, { useEffect, useState } from "react";
import {
  CheckList,
  CoverLetter,
  FixedSalary,
  NumberInput,
  RadioList,
  RangeSalary,
  SelectInput,
  SelectionStage,
  TextAreaInput,
  TextInput,
} from "./FormInputs";

function InternshipDetails({ campaign_data }) {

  return (
    <div className="campaign-form__container">
      <h4 className="campaign-form__container--title">Internship Details</h4>
      <div className="form-section">
        <TextInput
          label="Internship Profile"
          placeholder="e.g. React Web Developer"
          id="profile"
          default_value={campaign_data?.campaign_title || ""}
          size={60}
        />
        <SelectInput
          label="Skills required"
          placeholder="e.g. Javascript"
          id="skills"
          default_value={campaign_data?.skills_required || []}
          size={10}
        />
        <RadioList
          name="internship-type"
          radioInfo={["Office", "Remote", "Hybrid"]}
          id="work_type"
          label="Internship Type"
          default_value={campaign_data?.work_type}
        />
        <SelectInput
          label="City/Cities"
          placeholder="e.g. Mumbai"
          id="cities"
          default_value={campaign_data?.locations || []}
          size={5}
        />
        <NumberInput
          label="Number of Openings"
          placeholder="e.g. 4"
          id="openings"
          default_value={campaign_data?.vacancies || ""}
        />
        <NumberInput
          label="Minimum Experience (yrs)"
          placeholder="e.g. 2"
          id="min-exp"
          default_value={campaign_data?.min_experience || ""}
        />
        <NumberInput
          label="Internship Duration (Months)"
          placeholder="e.g. 4 months"
          id="intern-duration"
          default_value={campaign_data?.duration || ""}
        />
        <TextAreaInput
          label="Intern’s responsibilities"
          id="responsibilities"
          preloaded_msg={[
            "Selected intern's day-to-day responsibilities include:",
            "",
            "1. ",
            "2. ",
            "3. ",
          ]}
          default_value={campaign_data?.responsibility || ""}
          size={2000}
        />
        <TextAreaInput
          label="Do you have any candidate preferences?"
          id="preference"
          placeholder="e.g. Only Computer Science Graduates"
          optional={true}
          default_value={campaign_data?.preference || ""}
          size={500}
        />
        <SelectionStage label="Selection Stage" default_value={campaign_data?.section_id} />
      </div>
    </div>
  );
}

function Stipend({ campaign_data }) {
  const [stipend, setStipend] = useState("fixed");
  const perks = [
    ["Certificate", "Flexible work hours", "Informal dress code"],
    ["Letter of recommendation", "Work 5 days a week", "Free snacks & beverages"],
  ]

  useEffect(() => {
    if (campaign_data?.stipend_type === 0) {
      setStipend("fixed");
    }
    else if (campaign_data?.stipend_type === 1) {
      setStipend("unfixed");
    }
    else if (campaign_data?.stipend_type === 2) {
      setStipend("unpaid");
    }

  }, [campaign_data]);

  return (
    <div className="campaign-form__container">
      <h4 className="campaign-form__container--title">Stipend & perks</h4>
      <div className="form-section">
        <div id="stipend" className={stipend==="unpaid"?"margin-bottom":""}>
          <h4 className="form-input-heading">Stipend</h4>
          <div className="form-horizontal border-line">
            <div className="form-group">
              {(stipend === "fixed") ? 
                (<input
                  type="radio"
                  id="stipend1"
                  name="stipend"
                  defaultChecked
                  onChange={() => setStipend("fixed")}
                />) :
                (<input
                  type="radio"
                  id="stipend1"
                  name="stipend"
                  onChange={() => setStipend("fixed")}
                />)
              }
              <label htmlFor="stipend1">Fixed</label>
            </div>
            <div className="form-group">
              {(stipend === "unfixed") ? 
                (<input
                  type="radio"
                  id="stipend2"
                  name="stipend"
                  defaultChecked
                  onChange={() => setStipend("unfixed")}
                />) :
                (<input
                  type="radio"
                  id="stipend2"
                  name="stipend"
                  onChange={() => setStipend("unfixed")}
                />)
              }
              <label htmlFor="stipend2">Negotiable</label>
            </div>
            <div className="form-group">
              {(stipend === "unpaid") ? 
                (<input
                  type="radio"
                  id="stipend3"
                  name="stipend"
                  defaultChecked
                  onChange={() => setStipend("unpaid")}
                />) :
                (<input
                  type="radio"
                  id="stipend3"
                  name="stipend"
                  onChange={() => setStipend("unpaid")}
                />)
              }
              <label htmlFor="stipend3">Unpaid</label>
            </div>
          </div>

          {stipend === "fixed" && (
            <FixedSalary
              id="intern-salary"
              placeholder="e.g 10000"
              default_value={campaign_data?.stipend_amount?.amt}
              salary_release={campaign_data?.stipend_release}
            />
          )}

          {stipend === "unfixed" && (
            <RangeSalary
              id="intern-salary"
              placeholder1="e.g 10000"
              placeholder2="e.g 15000"
              default_value={campaign_data?.stipend_amount}
              salary_release={campaign_data?.stipend_release}
            />
          )}

          <div className="error">
            <i className="uil uil-exclamation-octagon"></i>
            <p className="error--text">This field is required</p>
          </div>
        </div>

        <CheckList
          label="Perks"
          optional={true}
          id="perks"
          checks={perks}
          default_checks={campaign_data?.perks || []}
        />

        <RadioList
          name="ppo"
          radioInfo={["Yes", "No"]}
          id="ppo"
          label="Does this internship come with a pre-placement offer (PPO)?"
          default_value={(campaign_data?.ppo)?0:1}
        />
      </div>
    </div>
  );
}

function InternshipForm({ submitFunc, mode, campaign_data }) {
  return (
    <>
      {/* Internship Details */}
      <InternshipDetails campaign_data={campaign_data} />

      {/* Stipend and Perks */}
      <Stipend campaign_data={campaign_data} />

      {/* Cover Letter */}
      <CoverLetter campaign_data={campaign_data} que_size={300} />

      {/* Form Buttons */}
      <div className="campaign-form__buttons">
        {/* <DropDown upward_menu={true} /> */}

        <button className="campaign-form__buttons--create" onClick={submitFunc}>
          <i className="uil uil-rocket" />
          {mode === "create" ? "Create New" : "Update"}
        </button>
      </div>
    </>
  );
}

export default InternshipForm;

import React, { useEffect, useRef, useState } from 'react'
import Popup from './Popup';
import { api } from '../../../backend';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';


function Stage({ title, stage_id, section_id, setStages }) {
  const navigate = useNavigate();
  const session_id = localStorage.getItem("session_id");
  const [showPopup, setShowPopup] = useState(false);
  const stage_box = useRef(null);

  useEffect(() => {
    stage_box.current.addEventListener("dragstart", () => {
      setTimeout(() => stage_box.current.classList.add("dragging"), 0);
    });

    stage_box.current.addEventListener("dragend", () => {
      stage_box.current.classList.remove("dragging");
      const allBoxes = document.querySelectorAll(".stage_section__stages__box");
      const stage_positions = [];
      allBoxes.forEach((box) => {
        stage_positions.push(box.dataset.stage);
      });
      rearrangePosition(stage_positions);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStage = async (value) => {
    setShowPopup(false);
    stage_box.current.querySelector("p").innerText = value;
    try {
      await api.put(`/company/section/stages?session_id=${session_id}&section_id=${section_id}`, { stage_name: value, stage_id });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        stage_box.current.querySelector("p").innerText = title;
        alert(err.response.data.msg);
      }
    }
  }


  const deleteStage = async () => {
    const confirm = window.confirm('Are you sure you want to delete this stage?');
    if (!confirm) {
      return;
    }

    setShowPopup(false);
    setStages((prv) => prv.filter(stage => stage.stage_id !== stage_id));
    try {
      await api.delete(`/company/section/stages?session_id=${session_id}&section_id=${section_id}&stage_id=${stage_id}`);
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  const rearrangePosition = async (stage_positions) => {
    try {
      await api.put(`/company/section/stages/position?session_id=${session_id}&section_id=${section_id}`, { stage_positions });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_type');
        navigate("/login");
      } else {
        alert(err.response.data.msg);
      }
    }
  }

  return (
    <>
      {showPopup && (
        <Popup
          key={1}
          cancelFnc={() => setShowPopup(false)}
          type="stage"
          action="update"
          value={title}
          returnFun={updateStage}
        />
      )}

      <div className="stage_section__stages__box" ref={stage_box} draggable="true" data-stage={stage_id} >
        <div className="stage_section__stages__box--heading">
          <h3>stage: </h3>
          <p>{title}</p>
        </div>

        <div className="stage_section__stages__box--controls">
          <button className="stage-btn hamburger" >
            <i className="uil uil-bars"></i>
          </button>

          <button className="stage-btn edit" onClick={() => setShowPopup(true)} >
            <i className="uil uil-edit"></i>
          </button>

          <button className="stage-btn remove" onClick={deleteStage} >
            <i className="uil uil-trash-alt"></i>
          </button>
        </div>
      </div>
    </>
  )
}

function Stages({ data, section_id, setStages }) {
  const container = useRef(null);

  useEffect(() => {
    container.current.addEventListener("dragover", (e) => {
      e.preventDefault();
      const draggingItem = container.current.querySelector(".stage_section__stages__box.dragging");
      let siblings = [...container.current.querySelectorAll(".stage_section__stages__box:not(.dragging)")];
      let nextSibling = siblings.find(sibling => {
        return e.clientY <= (sibling.offsetTop + sibling.offsetHeight);
      });
      container.current.insertBefore(draggingItem, nextSibling);
    });

    container.current.addEventListener("dragenter", e => e.preventDefault());
  }, []);

  return (
    <div className="stage_section__stages" ref={container}>
      {data?.map((stage) => (
        <Stage
          title={stage?.stage_name}
          stage_id={stage?.stage_id}
          key={uuidv4()}
          section_id={section_id}
          setStages={setStages}
        />
      ))}
  </div>
  )
}

export default Stages;
import React, { useEffect } from "react";

const COMING_SOON_IMG = process.env.REACT_APP_COMING_SOON_IMG;

function Main() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="nothing-found">
      <img src={
        COMING_SOON_IMG + "?tr=h-280,w-280"
      } alt="nothing-found" />
      <h1>Feature coming soon</h1>
    </div>
  );
}

export default Main;
